<template>
  <div>
    <div class="sectionNOOO" :class="{ posting: posting }">
      <div class="playGroundTab">
        <div class="columns is-variable is-3">
          <div class="column">
            <div class="request box" :class="op.method">
              <h2>REQUEST</h2>
              <!-- 
MAIN PARAM
                   

            xxx

            {{ paramsData }} ======== {{ paramValuesArray }}

              ---->

              <div class="mainParams" v-if="hasMainParams" style="margin-bottom: 20px">
                <div class="fields field-group columns">
                  <div class="f column" v-for="i in op.urlParameters" :key="i + 'gdfg333df'">
                    <b-field :label="i" label-position="inside" horizontalNONO>
                      <b-input
                        size="is-large"
                        :name="i"
                        v-model="paramsData[i]"
                        :loading="posting"
                        :disabled="posting"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
                <!--  
                <b-collapse aria-id="co47n342tentIdForA11y2" class="panel" animation="slide" v-model="isOpenParams">
                  <template #trigger>
                    <div
                      class="panel-heading"
                      role="button"
                      aria-controls="contentIdForA11y2"
                      :aria-expanded="isOpenParams"
                    >
                      <strong>Params</strong>
                      <b-tooltip
                        :multilined="true"
                        position="is-top"
                        type="is-dark"
                        label="Main params that are passed in the URL"
                      >
                        <i class="fal fa-question-circle" aria-hidden="true"></i>
                      </b-tooltip>
                    </div>
                  </template>
        
                  <div class="panel-block">
                    <div class="fields field-group columns">
                      <div class="f column" v-for="i in op.urlParameters" :key="i + 'gdfg333df'">
                        <b-field :label="i" label-position="inside" horizontalNONO>
                          <b-input
                            size="is-large"
                            :name="i"
                            v-model="paramsData[i]"
                            :loading="posting"
                            :disabled="posting"
                          ></b-input>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </b-collapse>-->

                <!-- --  
              <div class="columns">
                <div class="column is-narrow">
                  <h1 class="title is-5">
                    Main Params
                    <b-tooltip
                      :multilined="true"
                      position="is-top"
                      type="is-dark"
                      label="Main params that are passed in the URL"
                    >
                      <i class="fal fa-question-circle" aria-hidden="true"></i>
                    </b-tooltip>
                  </h1>
                </div>
              </div>

              <div class="fields field-group">
                <div class="f" v-for="i in op.urlParameters" :key="i + 'gdfg333df'">
                  <b-field :label="i" label-position="on-border" horizontalNONO>
                    <b-input
                      size="is-"
                      :name="i"
                      v-model="paramsData[i]"
                      :loading="posting"
                      :disabled="posting"
                    ></b-input>
                  </b-field>
                </div>
              </div>

               -->
              </div>

              <!--  
            <div v-else class="empty">This methods requires no params</div>
             -->

              <!-- 
POST PARAM
                     ---->

              <div class="postedParams" v-if="isPost">
                <b-collapse aria-id="c34566ontentIdForA11y222" class="panel" animation="slide" v-model="isOpenData">
                  <template #trigger>
                    <div
                      class="panel-heading"
                      role="button"
                      aria-controls="contentIdForA11y2"
                      :aria-expanded="isOpenData"
                    >
                      <div class="helpers" style="float: right"></div>
                      <strong>Data</strong>
                    </div>
                  </template>

                  <div class="panel-block">
                    <div class="wrap-code-and-debug">
                      <CodeEditor v-model="postedDataTxt" class="bro_code"></CodeEditor>
                      <br />
                      <!-- --  
                    {{ postedData }}
                    <br /> -->
                      <b-button
                        v-if="!postedDataTxtError"
                        icon-left="indent"
                        icon-pack="fas"
                        type="is-light"
                        size="is-small"
                        @click="formatPostedDataTxt"
                        >Format data</b-button
                      >
                      <span v-if="postedDataTxtError" class="tag is-danger is-light">Parsing error...</span>
                    </div>
                  </div>
                  <!-- 
                    LEGACY editor
                  <div class="panel-block">
                    <vue-json-editor
                      v-model="postedData"
                      mode="code"
                      :show-btns="false"
                      :expandedOnStart="true"
                      :mainMenuBar="false"
                      style="width: 100%"
                    ></vue-json-editor>
                  </div>
                   -->
                </b-collapse>

                <!--  
              <div class="columns">
                <div class="column is-narrow">
                  <h1 class="title is-5">
                    Posted data
                    <b-tooltip
                      :multilined="true"
                      position="is-top"
                      type="is-dark"
                      label="The data object posted to the API method."
                    >
                      <i class="fal fa-question-circle" aria-hidden="true"></i>
                    </b-tooltip>
                  </h1>
                </div>
                <div class="column"></div>
                <div class="column is-narrow">
                  <b-tabs type="is-toggle" size="is-small" v-model="tabPostStyle">
                    <b-tab-item value="form" label="" icon="edit"></b-tab-item>
                    <b-tab-item value="json" icon="code"></b-tab-item>
                  </b-tabs>
                </div>
              </div>

              <div class="fields field-group">
                <div class="f">
                  <b-field :label="i">
                    <vue-json-editor
                      v-model="postedData"
                      mode="code"
                      :show-btns="false"
                      :expandedOnStart="true"
                    ></vue-json-editor>
                  </b-field>
                </div>
              </div>


 -->
              </div>

              <!-- 
OPTIONS PARAM
                     ---->

              <div class="optionsParams" v-if="!isPost">
                <b-collapse aria-id="c5885ontentIdForA11y2" class="panel" animation="slide" v-model="isOpenOption">
                  <template #trigger>
                    <div
                      class="panel-heading"
                      role="button"
                      aria-controls="contentIdForA11y2"
                      :aria-expanded="isOpenOption"
                    >
                      <strong>Options</strong>
                      <b-tooltip
                        :multilined="true"
                        position="is-top"
                        type="is-dark"
                        label="An object containing extra options that will be enconded in the URL."
                      >
                        <i class="fal fa-question-circle" aria-hidden="true"></i>
                      </b-tooltip>
                    </div>
                  </template>

                  <p class="panel-tabs">
                    <a :class="{ 'is-active': tabParamStyle == 'form' }" @click="tabParamStyle = 'form'">Form</a>
                    <a :class="{ 'is-active': tabParamStyle == 'json' }" @click="tabParamStyle = 'json'">JSON object</a>
                  </p>

                  <!-- -   
                <b-tabs type="is-toggle" size="is-small" v-model="tabParamStyle">
                  <b-tab-item value="form" label="" icon="edit"></b-tab-item>
                  <b-tab-item value="json" icon="code"></b-tab-item>
                </b-tabs> -->

                  <div class="panel-block">
                    <!-- -- 
                  begin block panel-->

                    <vue-json-editor
                      v-if="tabParamStyle == 'json'"
                      v-model="optionsData"
                      mode="code"
                      :show-btns="false"
                      :expandedOnStart="true"
                      style="width: 100%"
                    ></vue-json-editor>

                    <div class="fieldsView" v-if="tabParamStyle == 'form'">
                      <div class="fieldsCat" v-for="fieldsCat in optionsCategories" :key="'catfielt ' + fieldsCat.name">
                        <b-field horizontalNO>
                          <h1 class="title is-6">{{ fieldsCat.name }}</h1>
                        </b-field>
                        <div class="fields field-group">
                          <div class="f" v-for="i in fieldsCat.fields" :key="i.id + 'gdfgdf'">
                            <br />
                            <b-field horizontalNO label-position="on-border" :label="i.id">
                              <b-switch
                                v-if="i.type == 'bool'"
                                size="is-"
                                :name="i.id"
                                type="is-info"
                                :placeholder="'ex: ' + i.example"
                                v-model="optionsData[i.id]"
                                :loading="posting"
                                :disabled="posting"
                              ></b-switch>

                              <b-input
                                v-else
                                size="is-"
                                name="i.id"
                                :placeholder="'ex: ' + i.placeholder"
                                v-model="optionsData[i.id]"
                                :loading="posting"
                                :disabled="posting"
                                :type="i.nativeType"
                              ></b-input>

                              <span class="tags" v-if="true">
                                <span v-for="k in i.examples" :key="i.id + k + '4wfdn7'" class=" ">
                                  <span class="tag" @click="setOptionsData(i.id, k)">{{ k }}</span>
                                </span>
                              </span>

                              <!-- --TODO: move inside the label 
               
                        <b-tooltip
                          :multilined="i.desc && i.desc.length > 20"
                          position="is-top"
                          type="is-dark"
                          :label="i.desc"
                        >
                          <i class="fal fa-question-circle" aria-hidden="true"></i>
                        </b-tooltip>
                               -->
                            </b-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- --
                  END block panel  -->
                  </div>
                </b-collapse>

                <!--   
              <div class="columns">
                <div class="column is-narrow">
                  <h1 class="title is-5">
                    Options
                    <b-tooltip
                      :multilined="true"
                      position="is-top"
                      type="is-dark"
                      label="An object containing extra options that will be enconded in the URL."
                    >
                      <i class="fal fa-question-circle" aria-hidden="true"></i>
                    </b-tooltip>
                  </h1>
                </div>

                <div class="column"></div>
                <div class="column is-narrow">
                  <b-tabs type="is-toggle" size="is-small" v-model="tabParamStyle">
                    <b-tab-item value="form" label="" icon="edit"></b-tab-item>
                    <b-tab-item value="json" icon="code"></b-tab-item>
                  </b-tabs>
                </div>
              </div>
            -->
              </div>
              <!-- -- 
            <vue-json-editor
              v-model="optionsCategories"
              mode="code"
              :show-btns="false"
              :expandedOnStart="true"
            ></vue-json-editor>
-->

              <!--       <vue-json-editor
              v-if="tabParamStyle == 'json'"
              v-model="optionsData"
              mode="code"
              :show-btns="false"
              :expandedOnStart="true"
            ></vue-json-editor>-->

              <!-- -- @json-change="onJsonPostDataChange" -->

              <!-- SEND ROW -->
              <div class="columns is-vcentered">
                <div class="column"></div>
                <div class="column is-narrow">
                  <!--    {{ tokenSelectorData }} === tokenSelectorData
                  <b-dropdown v-model="authTokenIndex" aria-role="list">
                    <template #trigger>
                      <div> 
                        <b-button
                          :label="authToken.name"
                          icon-pack="mdi"
                          pack="mdi"
                          :icon-left="authToken.icon"
                          icon-right="menu-down"
                          type="is-lighNNt"
                          size="is-small"
                          rounded
                        />
                      </div>
                    </template>


                 
                    <b-dropdown-item
                      :value="index"
                      aria-role="listitem"
                      v-for="(i, index) in authTokens"
                      :key="index + 'fdsfsd5'"
                    >
                      <div class="media">
                        <b-icon class="media-left" pack="mdi" icon-pack="mdi" :icon="i.icon"></b-icon>
                        <div class="media-content">
                          <h3>{{ i.name }}</h3>
                          <small>{{ i.d }}</small>
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <br /> 
                  <b-switch
                    v-model="useDevBackend"
                    type="is-dark"
                    size="is-small"
                    :left-label="false"
                    style="text-align: right"
                    @input="baseUrlChange"
                  >
                    {{ useDevBackend ? "dev" : "prod" }}
             
                  </b-switch> -->

                  <TokenSelector
                    :tokenStore="tokenStore"
                    @onTokenChange="onTokenChange"
                    @onServerChange="onServerChange"
                  />

                  <!-- 
                  {{ selectedApiBaseUrl }} == selectedApiBaseUrl
                  <br />
                  {{ selectedTokenValue }} == selectedTokenValue
                --></div>

                <div class="column is-narrow">
                  <b-button
                    size="is-large"
                    :class="MethodColors[op.method]"
                    type="is-darkNO is-outlinedNO"
                    @click="makeRequest"
                    :loading="posting"
                    :disabled="posting"
                    style="letter-spacing: 3px"
                  >
                    {{ op.method || "SEND" }}
                    <i class="far fa-arrow-right" aria-hidden="true"></i>
                  </b-button>
                </div>
              </div>
            </div>
            <!-- extra garbage  -->
            <hr />

            <b-collapse aria-id="co88455ntentIdFo22frA11y2" class="panel" animation="slide" v-model="isOpenCode">
              <template #trigger>
                <div class="panel-heading" role="button" aria-controls="contentIdForA11y2" :aria-expanded="isOpenCode">
                  <strong>Code</strong>
                </div>
              </template>

              <p class="panel-tabs">
                <a class="is-active">JS Lib</a>
                <a>Fetch</a>
                <a>Axios</a>

                <a>Python</a>
                <a>PHP</a>
                <a>Curl</a>
              </p>
              <div class="panel-block">
                <div v-if="tabCode == 'lib'">
                  <CodeEditor
                    :language_selector="false"
                    :languages="[
                      ['javascript', 'JS'],
                      ['python', 'Python'],
                    ]"
                    :value="codeBasicCall"
                    theme="dark"
                    class="bro_code"
                    :read_only="true"
                    :hide_header="false"
                    :copy_code="true"
                    :wrap_code="true"
                    font_size="14px"
                  ></CodeEditor>
                  <p>or use with <a @click="showCallCatch = true">then() and catch()</a></p>
                  <CodeEditor
                    v-if="showCallCatch"
                    :value="codeBasicCallCatch"
                    theme="dark"
                    class="bro_code"
                    :read_only="true"
                    :hide_header="true"
                    :copy_code="true"
                  ></CodeEditor>
                </div>
              </div>
            </b-collapse>

            <!--   
            <b-field>
              <b-switch v-model="useDevBackend" type="is-warning">
                Use Dev server ({{ proj.baseUrlDev }})
                <b-tooltip type="is-dark" multilined label="You can always edits these later under settings">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </b-switch>
            </b-field>

             -->
          </div>

          <div class="column verti-sep is-narrow divider">
            <div style="height: 100%; background: #f1f1f1; width: 1px; border-radius: 10px"></div>
          </div>

          <div class="column response">
            <!--  
            RESPONSEEEE

  


            <b-field label="API Endpoint">
              <b-input v-model="requestUrl" readonly placeholder="Enter the API endpoint"></b-input>
              <b-button type="is-info is-outlined" :loading="posting" :disabled="posting"><small> GET</small></b-button>
              <i class="fal fa-link" aria-hidden="true"></i>
            </b-field>

            <h1 class="title is-5">Response <span class="tag">Array</span></h1>
          -->
            <!-- -- 
            <b-field label="Response">
              <b-input type="textarea" v-model="response" readonly></b-input>
            </b-field>
             -->

            <div v-if="noRequestAttempted" style="margin: auto; text-align: center">
              <div class="empty text-align-center">
                <img
                  style="height: 200px"
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/disp_still/9f6465159728551.63a456b253488.gif"
                />
                <h2 class="title is-5">{{ selectedServer.id }} api</h2>
                <small> {{ selectedServer.url }} </small>

                <p v-if="String(selectedServer.id).includes('dev')">
                  Use <code>midrun dev</code> to test on local setup. <br />
                </p>
                <br />

                <router-link
                  v-if="!proj.apiBaseUrl"
                  :to="{ path: '/' + $route.params.project + '/settings' }"
                  active-class="is-activeNN"
                  exact
                >
                  <b-button type="is-dark">
                    <span class="icon is-small"><i class="far fa-cog" aria-hidden="true"></i></span>
                    <span>Configure a production host</span>
                  </b-button>
                </router-link>
              </div>

              <br />
            </div>
            <div v-else>
              <!-- 
              <span class="tag is-success is-outlined">200</span>
                -->

              <b-collapse
                v-if="!errorMsg"
                aria-id="co2214ntentIdForA11y2"
                class="panel"
                animation="slide"
                v-model="isOpenRes"
              >
                <template #trigger>
                  <div class="panel-heading" role="button" aria-controls="contentIdForA11y2" :aria-expanded="isOpenRes">
                    <strong>Response</strong>
                    <span v-if="op.responseType" class="tag">{{ op.responseType }}</span>
                  </div>
                </template>
                <p class="panel-tabs">
                  <a class="is-active">JSON</a>
                  <a>Tree</a>
                  <a>Raw</a>
                </p>
                <div class="panel-block">
                  <vue-json-pretty :data="response" />

                  <!-- 
                  <CodeEditor
                    key="apiOk"
                    v-if="!errorMsg"
                    v-model="responseTxt"
                    theme="dark"
                    class="bro_code"
                    :read_only="true"
                    :hide_header="true"
                    :copy_code="true"
                    max_height="500px"
                    font_size="12px"
                  ></CodeEditor>

                  
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
                  Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu
                  lectus. <br />
                  Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                    -->
                </div>
              </b-collapse>

              <b-button
                icon-left="key"
                icon-pack="fas"
                type="is-dark"
                v-if="response && !errorMsg && (response.token || String(op.operationId).includes('login'))"
                style="padding-top: 10px"
                @click="addToken(response)"
                >Save token</b-button
              >

              <!-- 

                 this.response.login || this.response.userId || this.response.email || this.response.name
              <h1 class="title is-5">Response <span class="tag">Array</span></h1>
  -->
              <div class="err" style="margin-bottom: 30px">
                <CodeEditor
                  v-if="errorMsg"
                  v-model="errorMsg"
                  theme="light"
                  class="bro_code bro_error"
                  :read_only="true"
                  :hide_header="true"
                  :copy_code="true"
                  header="error"
                  style="border: 2px dotted red"
                  font_size="16px"
                  key="apierr"
                  :wrap_code="true"
                ></CodeEditor>
              </div>

              <div class="extras">
                <b-collapse aria-id="co88455ntentIdFo22frA11y2" class="panel" animation="slide" v-model="isOpenStats">
                  <template #trigger>
                    <div
                      class="panel-heading"
                      role="button"
                      aria-controls="contentIdForA11y2"
                      :aria-expanded="isOpenStats"
                    >
                      <strong>Performance</strong>
                    </div>
                  </template>
                  <!-- 
                  <p class="panel-tabs">
                    <a class="is-active">Overview</a>
                    <a>Public</a>
                    <a>Private</a>
                  </p> -->
                  <div class="panel-block">
                    <div class="columns statsSummary" style="width: 100%">
                      <div class="column">
                        <div class="perfWrap">
                          <span v-for="(i, index) in perf.msIcon" :key="i + index">
                            <emoji :emoji="i" set="apple" :size="64" />
                          </span>

                          <br />
                          <h4 class="title is-5">{{ perf.msTxt }}</h4>
                        </div>
                      </div>
                      <div class="column">
                        <div class="perfWrap">
                          <emoji emoji="package" set="apple" :size="64" />
                          <br />
                          <h4 class="title is-5">{{ perf.sizeTxt }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="panel-block">
                    <br />
                    <p>
                      View more in the debug console:
                      <span v-if="isMac"> <kbd>⌘</kbd>+<kbd>Opt</kbd>+<kbd>i</kbd> </span>
                      <span v-else> <kbd>Ctrl</kbd>+<kbd>Shift</kbd>+<kbd>i</kbd></span>
                    </p>
                  </div>
                </b-collapse>

                <!-- 

                <hr />

                <vue-json-editor
                  v-model="response"
                  mode="code"
                  :show-btns="false"
                  :expandedOnStart="true"
                ></vue-json-editor>

                <h1 class="title is-5">Caching</h1>

                xxx

                <h1 class="title is-5">Operation deets</h1>

                <vue-json-editor v-model="op" mode="code" :show-btns="false" :expandedOnStart="true"></vue-json-editor>
            
              --></div>
            </div>
          </div>
        </div>

        <!-- -- 
        <b-field label="API Endpoint">
          <b-input v-model="endpoint" placeholder="Enter the API endpoint"></b-input>
        </b-field>
        <b-field label="HTTP Method">
          <b-select v-model="method" :options="methodOptions"></b-select>
        </b-field>
        <b-field label="Request Body">
          <b-input type="textarea" v-model="body"></b-input>
        </b-field>
        <b-field>
          <b-button @click="makeRequest">Send Request</b-button>
        </b-field>
  -->
      </div>
    </div>
  </div>
</template>

<script>
import { MethodColors } from "@/common/utils.js";

import _ from "lodash";
import JSON5 from "json5";
import axios from "axios";
import { getApiInstance, createApiInstance } from "../../apiInstance.js";

import vueJsonEditor from "vue-json-editor";

import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

import { Emoji } from "emoji-mart-vue";

import TokenSelector from "./tokenSelector.vue";
//import tokenStore from './tokenstore';

import TokenStore from "../../libs/tokenStore.js";

function encodeQueryData(data) {
  const ret = [];
  for (let d in data) ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  return ret.join("&");
}

function parseOptionSpec(i) {
  i.nativeType = "text"; // default
  i.placeholder = i.example; // default.
  if (i.type == "textarea" || i.type == "multiline") i.nativeType = "textarea";
  if (i.type == "number") {
    i.nativeType = "number";
    i.narrow = true;
  }

  // create clickable examples.
  if (i.example) {
    //if array
    if (typeof i.example == "object") {
      i.examples = i.example.map((i) => String(i).trim());
    } else {
      i.examples = [i.example]; //single pos array my bro
    }
  }

  return i;
}

const authTokens = [
  { name: "Public (no token)", token: "", icon: "earth" },
  { name: "Logged user", token: "123466", icon: "people" },
  { name: "Bob (owner)", token: "123466", icon: "user-plus" },
  { name: "Admin user", token: "123466", icon: "cog" },
];

function msIcon(s) {
  let i;
  // https://jm-david.github.io/emoji-mart-vue/
  // apple lkit
  if (s > 10000) {
    i = "turtle turtle";
  } else if (s > 5000) {
    i = "turtle";
  } else if (s > 2000) {
    i = "hourglass hourglass";
  } else if (s > 700) {
    i = "hourglass";
  } else if (s > 300) {
    i = "clock1";
  } else if (s > 150) {
    i = "rabbit2";
  } else if (s > 75) {
    i = "brabbit2 rabbit2";
  } else if (s > 30) {
    i = "zap";
  } else {
    i = "zap zap";
  }
  return i.split(" ");
}

function getBytes(obj) {
  //https://stackoverflow.com/questions/23318037/size-of-json-object-in-kbs-mbs
  return Buffer.from(JSON.stringify(obj)).length;
}
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

var isNumber = function isNumber(value) {
  return !isNaN(value);
  return typeof value === "number" && isFinite(value);
};

function objectStyleJson(object) {
  return JSON5.stringify(object, 0, 2);
  //no stupid quotes.
  const json = JSON.stringify(object, 0, 2); // {"name":"John Smith"}
  //console.log(json);
  const unquoted = json.replace(/"([^"]+)":/g, "$1:");
  return unquoted;
}

export default {
  components: {
    TokenSelector,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    vueJsonEditor,
    VueJsonPretty,
    Emoji,
  },
  data() {
    return {
      MethodColors,

      tokenStore: null,
      isMac: navigator.platform.toUpperCase().indexOf("MAC") >= 0,
      // api: getApiInstance(this.proj.id), //instance of client api
      api: {},
      tokenSelectorData: null, // will be loaded from token-store
      authTokens,
      authTokenIndex: 0,
      selectedServer: {},

      posting: false,
      postedData: {},
      postedDataTxt: "",
      postedDataTxtError: false,
      // parmas styling
      //exampels
      paramRequired: "userId orderId".split(" "),
      paramOptional: "limit sort:string noCache:bool otherParam".split(" "),

      tabParamStyle: "form",
      tabPostStyle: "json",
      tabCode: "lib",
      optionsData: {},
      paramsData: {},

      //baseic
      endpoint: "",
      method: "GET",
      methodOptions: ["GET", "POST", "PUT", "PATCH", "DELETE"],
      body: "",
      response: "",
      responseTxt: "",
      errorMsg: "",

      isOpenRes: false,

      isOpenData: true,
      isOpenOption: false,
      isOpenStats: true,
      isOpenParams: true,
      isOpenCode: true,
      noRequestAttempted: true,
      useDevBackend: false,
      showCallCatch: false,

      perf: {}, //meta of req perforamnce
    };
  },
  watch: {
    // whenever question changes, this function will run
    postedDataTxt(newT, oldT) {
      try {
        var ok = JSON5.parse(newT);

        this.postedData = ok;
        this.postedDataTxtError = false;
      } catch (error) {
        this.postedDataTxtError = true;
      }

      /*
      if (newQuestion.includes('?')) {
        this.getAnswer()
      }*/
    },
  },
  props: {
    op: {},
    ops: [],
    proj: Object, //
  },
  name: "playTab",
  sockets: {
    test: function (data) {
      console.log(data, "TEST SOCKET", 77441111);
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)');
    },
    "midrun-dev-tunnel-update": function (data) {
      //alert(4445999);
      //works
      console.log(data, "LOG", 432423);
      /* */
      this.$buefy.toast.open({
        message: "midrun dev -> New tunnel URL: " + data.proxy,
        type: "is-dark",
        position: "is-top-right",
        duration: 5000,
      });
      // console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)');
    },
  },
  beforeMount() {
    var i = this.proj.id;
    this.tokenStore = new TokenStore(this.proj.id, this.proj.apiBaseUrl, this.proj.apiBaseUrlDev);
  },
  mounted() {
    var i = this.proj.id;
    this.$socket.emit("midrun-ui-register", { projectId: i });

    // alert(i);
    //var p = this.$route.params.project;

    //  this.tokenStore = new TokenStore(this.proj.id, this.proj.apiBaseUrl, this.proj.apiBaseUrlDev);
    // may not be required to instanciate here?
    var api = getApiInstance(i, this.proj, this.ops);
    window.a = api;
    this.api = api;
  },
  methods: {
    onTokenChange(e) {
      this.tokenSelectorData = e;
      console.log(e);

      console.log("🤖🤖token changed", e);
      //set the token on api instance
      // alert("SET TOKEN: " + e.token.token);
      this.api.configUserToken(e.token.token || "");
    },
    onServerChange(e) {
      console.log("🤖🤖 🤖🤖 server + token changed", e);
      // recreate an api istance with a new host.
      this.selectedServer = e.server;
      var u = e.server.url; //'http://localhost:4455/v1'
      var opt = { baseUrl: u }; //"https://hahaha.co/dd"
      //recreate the api instance with new host
      //this.api = createApiInstance(this.proj.id, this.proj, this.ops, opt);
      this.recreateApiInstance(opt);
      // applu new selected token
      if (!e.token.token || e.token.token == "null") {
        //remove tokens from reqs
        this.api.configUserToken("");
      }
      // var tokenStoreSettings = this.tokenStoreSettings;
      var tok = this.tokenStore.getSelectedToken();
      console.log("🤖🤖 🤖🤖 server + token changed", tok);
      //  alert("SET TOKEN: " + e.token.token + "- " + tok.token);
      this.api.configUserToken(e.token.token);
    },

    formatPostedDataTxt() {
      // alert("bb");
      var a = JSON5.stringify(this.postedData, 0, 2);
      this.postedDataTxt = a;
    },
    baseUrlChange() {
      //  this.api.configBaseUrl("https://haha.com/v1");
      console.log(4234);
      var u = this.useDevBackend ? this.proj.apiBaseUrlDev : this.proj.apiBaseUrl; //'http://localhost:4455/v1'
      var opt = { baseUrl: u }; //"https://hahaha.co/dd"
      this.recreateApiInstance(opt);
      //called when the baseUrl is changing, the tok
    },
    recreateApiInstance(opt) {
      this.api = createApiInstance(this.proj.id, this.proj, this.ops, opt);
      window.c = this.api;
    },
    setOptionsData(id, v) {
      //console.log(id, v);
      this.optionsData[id] = v;
      //propagete!
      return true;
    },
    addToken(token, server) {
      //saves the token to local storage in an array of tokens, for the current server, unique to the current project
      this.tokenStore.addTokenToSelectedServer(_.clone(token));

      // buefy toast
      this.$buefy.toast.open({
        message: "Token added to current server",
        type: "is-dark",
        position: "is-bottom-right",
        duration: 2000,
      });
    },
    makeRequest() {
      this.posting = true;
      this.formatPostedDataTxt();
      this.perf = {};
      var startTime = new Date().getTime();
      this.noRequestAttempted = false;
      var api = this.api;
      var operationId = this.op.operationId;

      var options = this.optionsData;

      var paramValuesArray = _.clone(this.paramValuesArray);
      //alert(paramValuesArray);
      console.log(paramValuesArray, "paramValuesArray ++++");
      if (this.isPost) {
        var postDataCloned = JSON.parse(JSON.stringify(this.postedData));
        paramValuesArray.push(postDataCloned);
        // paramValuesArray.push(this.postedData);
      } //append posted data..
      console.log(paramValuesArray, "paramValuesArray ++++", paramValuesArray.length, paramValuesArray[0]);
      var op = api[operationId]; //get the operation from original spec.
      if (!op) {
        //show buefy toast danger of missing operation and console.
        this.$buefy.toast.open({
          message: "Operation not found in API instance spec: " + operationId,
          type: "is-danger",
          position: "is-bottom-right",
          duration: 2000,
        });
        console.error("Operation not found in spec: " + operationId);
      }
      // Or the system spec.
      /*
      if(!op){
        op = api.system[operationId];
      }*/
      let prom; //promise of the requests...
      if (paramValuesArray.length == 0) {
        prom = op(options);
      } else if (paramValuesArray.length == 1) {
        prom = op(paramValuesArray[0], options);
      } else if (paramValuesArray.length == 2) {
        prom = op(paramValuesArray[0], paramValuesArray[1], options);
      } else if (paramValuesArray.length == 3) {
        prom = op(paramValuesArray[0], paramValuesArray[1], paramValuesArray[2], options);
      }

      var end = 0;
      prom
        .then((i) => {
          end = new Date().getTime(); //save before recomputing display.
          this.response = i;
          this.responseTxt = JSON.stringify(i, null, 2);
          this.errorMsg = "";
        })
        .catch((e) => {
          // alert(e);
          end = new Date().getTime();
          this.errorMsg = String(e);
          this.response = "";
          this.responseTxt = "";
        })
        .then((i) => {
          this.posting = false;
          //do the performance anal

          var msElapsed = end - startTime;
          this.perf.ms = msElapsed;

          if (msElapsed > 1000) {
            this.perf.msTxt = Math.round(msElapsed * 10) / 10 / 1000 + " sec";
          } else {
            this.perf.msTxt = msElapsed + " ms";
          }
          this.perf.msIcon = msIcon(msElapsed);
          var bytes = getBytes(this.response || 0);
          this.perf.sizeTxt = formatBytes(bytes);
          //  var bytesStr = formatBytes(bytes, 1);
        });
      /*
      try {
        const config = {
          method: this.op.method,
          url: this.endpoint,
          data: this.body,
        };
        const res = await axios(config);
        this.response = JSON.stringify(res.data, null, 2);
      } catch (err) {
        this.response = JSON.stringify(err.response.data, null, 2);
      }*/
    },
  },
  computed: {
    tokenStoreSettings: function () {
      return this.tokenStore; //debug only.
    },
    selectedApiBaseUrl: function () {
      var c = this.tokenStoreSettings;
      //get selected server.
      var s = this.tokenStore.getSelectedServer();

      return s.url; //this.tokenStore; //debug only.
    },
    selectedTokenValue: function () {
      var c = this.tokenStoreSettings;
      //get selected server.
      var s = this.tokenStore.getSelectedToken();

      return s.token; //this.tokenStore; //debug only.
    },

    codeParamsInFucntion() {
      var p = this.op;
      if (!p.urlParameters || !p.urlParameters.length) return "";
      var prop = p.urlParameters.join(", ");
      return " " + prop + " ";
    },
    codeBasicCall() {
      //SEE
      //  https://www.npmjs.com/package/js-beautify

      var p = this.op;
      var proj = this.proj;
      return `//in an async function...
var data = await ${proj.id}.${p.operationId}(${this.paramStrForCodeSample})
//...
  `;
    },
    codeBasicCallCatch() {
      var p = this.op;
      return `//using promises...
API.${p.operationId}(${this.paramStrForCodeSample})
  .then(myData  => {
     // code to display myData
  }).catch(e => console.log('Api error: ', e))
   `;
    },
    hasMainParams() {
      return this.op.nbUrlParameters;
    },
    paramValuesArray() {
      var ar = this.op.urlParameters.map((i) => {
        //alert(i);
        return this.paramsData[i];
      });
      // TODO: if post or PUT, push the POST DATA
      // alert(this.op.method);
      /*
      if (this.op.method == "POST" || this.op.method == "PUT") {
        var postDataCloned = JSON.parse(JSON.stringify(this.postedData));
        ar.push(postDataCloned);
      }*/
      return ar;
    },

    paramStrForCodeSample() {
      var ar = this.op.urlParameters.map((i) => {
        //alert(i);
        return this.paramsData[i];
      });
      //  alert(ar.length);
      var str = "";
      // TODO: if post or PUT, push the POST DATA
      // alert(this.op.method);

      ar = ar.map((i) => {
        // alert(i + String(Number(i)));
        // return
        if (isNumber(i)) return i;
        return JSON5.stringify(i);
        //'"' + i + '"';
      });

      //TODO: first join the params. Then Json stringify the objects,
      // or better, format as objects without stupid json quotes.

      // TODO: append options!
      //optionsData: {},
      //  paramsData: {},
      /*
      if (this.isPost) {
        var postDataCloned = JSON.stringify(this.postedData, 0, 2);
        ar.push(postDataCloned);
      }
*/
      if (this.isPost) {
        //replace ugly garbage json txt
        var po = objectStyleJson(this.postedData);
        // alert(po);
        ar.push(po);
      }

      if (Object.keys(this.optionsData).length) {
        var jsonOptions = objectStyleJson(this.optionsData);
        ar.push(jsonOptions);
      }
      if (!ar.length) return "";
      str += " " + ar.join(", ") + " ";

      return str; // ar.join(", ");
    },
    authToken() {
      // TODO trap empties
      return this.authTokens[this.authTokenIndex];
    },

    requestUrl() {
      // TODO: Assemble query params for GET requests.
      var base = "hhtptptp://fsdfsd.cc/v1/";
      var methodUrl = "/bob";
      return base + methodUrl + this.urlParamsStr;
      //todo; valide this and flag potential error like dupe slashees
    },
    isGet() {
      return this.op.method == "GET";
    },
    isPost() {
      return this.op.method == "POST" || this.op.method == "PUT";
    },

    urlParams() {
      if (!this.isGet()) return {};
      return this.optionsData;
    },
    urlParamsStr() {
      // TODO: assemble via URL objects.
      return String(encodeQueryData(this.optionsData));
    },
    optionsCategories() {
      var allOptions = [
        // { id: "userId", required: true, example: "1234", desc: "The unique ID of the user", type: "string" },
        //{ id: "orderId", required: false, example: 4567, desc: "Unique ID of the order" },
        { id: "search", required: false, example: "bob", desc: "Text search string" },
        { id: "sort", required: false, example: "new", desc: "New, alpha, etc", type: "string" },
        { id: "limit", required: false, example: "200", desc: "limit number", type: "number" },
        {
          id: "message",
          required: false,
          example: "Hello my dear,",
          desc: "Text of your message, multiline string",
          type: "multiline",
        },
      ]; // of objects

      var systemOptions = [
        { id: "noCache", required: false, desc: "Ensure the server doesn't return a cached response", type: "bool" },
        { id: "debug", required: false, example: 4567, desc: "Force server to return more debug data", type: "bool" },
        {
          id: "sortTest",
          required: false,
          example: null,
          desc: "Choose between the sort order",
          type: "select",
          options: ["alpha", "new", "popular"],
        },
      ]; // of objects

      //console.log(ar);

      // TODO:  Append system params here

      var ar = _.chain(allOptions)
        .map((i) => {
          i.computedShiz = 2342335;
          return i;
        })
        .groupBy((i) => {
          return i.required ? "Required" : "Optional";
        })
        .value();

      var ar2 = [
        {
          name: "Function-specefic options",
          fields: ar["Required"],
        },
        {
          name: "Global options",
          fields: ar["Optional"],
        },
        {
          name: "System options",
          fields: systemOptions,
        },
      ];

      ar2 = _.map(ar2, (c) => {
        c.fields = _.map(c.fields, (i) => parseOptionSpec(i)); // augment all endpoints.
        return c;
      });
      return ar2;
    },
  },
};
</script>
<style scoped>
.posting .response {
  opacity: 0.2;
}

.panel {
  margin-bottom: 30px;
}

.panel-heading strong {
  color: #ccc;
}

.panel-heading strong {
  color: #888;
}

.perfWrap {
  text-align: center;
  margin-top: 10px;
}

/*  Request box   */

.box.request {
  background: #e9faff;
  border: solid 2px rgb(51, 51, 216);
  box-shadow: none;
}
.box.request .panel {
  box-shadow: 0px 0px 5px #b1ffdc;
}

.box.request .panel-block {
  background: #fefefe;
}
.box.request .collapse .collapse-content {
  background: white;
}
.box.request.GET .panel {
  box-shadow: 0px 0px 5px #acecff;
}
.box.request.PUT .panel {
  box-shadow: 0px 0px 5px #ffebd3;
}
.box.request.DELETE .panel {
  box-shadow: 0px 0px 5px #ff9bcc;
}
.box.request.GET h2 {
  color: #b5d8ff; /* same as border */
}
.box.request.PUT h2 {
  color: #ffcc8e; /* same as border */
}
.box.request.DELETE h2 {
  color: #ffa3b4; /* same as border */
}

.box.request.POST .panel {
  box-shadow: 0px 0px 5px #acffc1;
}
.box.request.GET {
  background: #e9faff;
  border-color: #b5d8ff;
}
.box.request.POST {
  background: #f0fff8;
  border-color: #a5f6d2;
}
.box.request.PUT {
  background: #fff8e3;
  border-color: #ffe4c4;
}
.box.request.DELETE {
  background: #ffe2e8;
  border-color: #ffa3b4;
}
.box.request h2 {
  color: #95e6c1;
  font-weight: 900;
  opacity: 1;
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-size: 18px;
}

.box.request .panel-heading,
.box.request .panel-tabs {
  background: white;
}

.box.request .panel {
  box-shadow: 0px 0px 5px #b1ffdc;
}
</style>
