<template>
  <div class="opTable">
    <div>
      <b-field grouped group-multiline> </b-field>

      <b-table
        :data="ops"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-rounded="isPaginationRounded"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        default-sort="user.first_name"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :page-input="hasInput"
        :pagination-order="paginationOrder"
        :page-input-position="inputPosition"
        :debounce-page-input="inputDebounce"
        hoverable
      >
        <b-table-column
          :visible="canShow('implementationStatus')"
          field="implementationStatusIndex"
          label="Dev"
          width="5"
          sortable
          v-slot="props"
        >
          <span>
            <span
              class="tag is-small small is-light"
              :class="ImplStatusColors[props.row.implementationStatus]"
              :key="props.row.implementationStatus"
            >
              <!--  
              {{ props.row.prodHandlerExists }}=== aaaa {{ props.row.implementationStatus }}
              {{ props.row.implementationStatusIndex }}
               -->
              <i :class="'fas  ' + ImplStatusIcons[props.row.implementationStatus]" style="margin-right: 5px"></i>
              {{ props.row.implementationStatus }}
            </span>
          </span>
        </b-table-column>

        <b-table-column
          :visible="canShow('prodHandlerExists')"
          field="prodHandlerExists"
          label="handler"
          width="5"
          sortable
          numeric
          v-slot="props"
        >
          <span :key="props.row.operationId + '42sdf'" :alt="props.row.prodHandlerExists">
            <i class="fal fa-lightbulb-slash" v-if="props.row.prodHandlerExists" style="color: #aaa"></i>
            <!-- 
              SEE: https://fontawesome.com/v5/search?q=lightbulb&o=r
              different state depending on errors, etc.
            <i class="fal fa-check" v-if="!props.row.prodHandlerExists"></i>
            -->
          </span>
        </b-table-column>

        <b-table-column
          :visible="canShow('requiredRole')"
          field="requiredRole"
          label="Role"
          width="5"
          sortable
          numeric
          v-slot="props"
        >
          <span :key="props.row.requiredRole" :alt="props.row.requiredRole">
            <i class="fal" :class="rolesIcons[props.row.requiredRole]"></i>
          </span>
        </b-table-column>

        <b-table-column :visible="canShow('operationId')" field="operationId" label="Id" sortable v-slot="props">
          <router-link
            @click.native="$scrollToTop"
            :to="{
              path: '/' + $route.params.project + '/' + props.row.operationId,
            }"
          >
            <span
              :style="
                ' display:block; height:10px; width:10px; float:left; margin-right:6px; margin-top:8px; background-color: ' +
                props.row.methodCoul +
                ' ; color: ' +
                props.row.methodTextCoul +
                ' ; font-weight: bold ; font-size:16px; padding: 2px ; border-radius:20px;'
              "
            >
            </span>

            {{ props.row.operationId }}
          </router-link>
        </b-table-column>

        <b-table-column
          :visible="canShow('urlParameters')"
          field="urlParameters"
          label="Params"
          width="5"
          sortable
          v-slot="props"
          ><!--  
          {{ props.row.urlParameters.join(", ") }} -->
          <span class="tag is-light" v-for="i in props.row.urlParameters" :key="i">
            {{ i }}
          </span>
        </b-table-column>

        <b-table-column
          :visible="canShow('method')"
          field="method"
          label="Method"
          width="5"
          sortable
          numeric
          v-slot="props"
        >
          <span class="tag is-small small" :class="MethodColors[props.row.method]">
            {{ props.row.method }}
          </span>
        </b-table-column>

        <b-table-column :visible="canShow('path')" field="path" label="Path" sortable v-slot="props">
          <small class="mono code">
            {{ props.row.path }}
          </small>
        </b-table-column>

        <b-table-column
          :visible="canShow('responseType')"
          field="responseType"
          width="5"
          label="Returns"
          sortable
          v-slot="props"
        >
          <small class="mono code">
            {{ props.row.responseType }}
          </small>
        </b-table-column>

        <b-table-column :visible="canShow('emoji')" field="emoji" label="" width="5" v-slot="props">
          {{ props.row.emoji }}
        </b-table-column>

        <b-table-column
          :visible="canShow('cacheStats')"
          field="cacheStats"
          width="522"
          label="Cache status"
          sortable
          v-slot="props"
        >
          <small class="mono code">
            <MiniStack :statsObj="props.row.cacheStats" />

            <!-- 
                      {{ props.row.cacheStats }}
            <MiniStack :values="[11, 22, 33]" :colors="['#1b4e6b', '#5c63a2', '#c068a8', '#ec7176', '#f4ab33']" />
 -->
          </small>
        </b-table-column>

        <!--

        <b-table-column field="tag" label="tag" sortable v-slot="props">
          <small class="tt">
            {{ props.row.tag.join(", ") }}
          </small>
        </b-table-column>

        <b-table-column field="specModified" label="modified" sortable centered v-slot="props">
          <small class="tt">
            {{ new Date(props.row.specModified).toLocaleDateString() }}
          </small>
        </b-table-column>

        <b-table-column field="specCreated" label="created" sortable centered v-slot="props">
          <small class="ttt small">
            {{ new Date(props.row.specCreated).toLocaleDateString() }}
          </small>
        </b-table-column>

         -- -->

        <!--
        <b-table-column label="Gender" v-slot="props">
          <span>
            <b-icon
              pack="fas"
              :icon="props.row.gender === 'Male' ? 'mars' : 'venus'"
            >
            </b-icon>
            {{ props.row.gender }}
          </span>
        </b-table-column> -->
      </b-table>
    </div>

    <!-- 
    <router-link :to="{ path: '/a/' + $route.params.project + '/endpoints' }" active-class="is-active" exact>
      <b-button rounded>Show all</b-button>
    </router-link>
     -->
  </div>
</template>

<script>
//import opCard from "../components/opCard.vue";

import MiniStack from "@/components/MiniStack.vue";

import { MethodColors } from "@/common/utils.js";

var rolesIcons = {
  public: "fa-minus",
  logged: "fa-user",
  owner: "fa-user-check",
  admin: "fa-lock",
};

var ImplStatusColors = {
  Idea: "is-light",
  Todo: "is-info",
  WIP: "is-warning",
  Stable: "is-success",
  Bug: "is-danger",
};

var ImplStatusIcons = {
  No: "fa-signal-alt-1",
  Idea: "fa-signal-alt-2",
  Todo: "fa-signal-alt-3",
  WIP: "fa-signal-alt ",
  Stable: "fa-check-circle",
  Bug: "fa-bug",
};

export default {
  components: {
    MiniStack,
    // opCard,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    // vueJsonEditor,
  },
  props: {
    ops: Array, //looppp
    visibleCols: Object,
  },
  methods: {
    canShow: function (colSlug) {
      // var visibleCols = this.visibleCols;
      if (!colSlug) return true;
      var colPref = this.visibleCols[colSlug];
      if (colPref === null) return true; //visible by default if unspecefied.
      return colPref; //true false
    },
  },
  data() {
    return {
      MethodColors,
      ImplStatusColors,
      ImplStatusIcons,
      rolesIcons,
      //  data,
      isPaginated: false,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 5,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",

      jsonPostData: 0,
    };
  },
};
</script>
<style>
.opTable a {
  color: black;
}
.opTable a:hover {
  text-decoration: underline;
}

.opTable table th {
  color: #bbb;
  font-weight: normal;
}
</style>
