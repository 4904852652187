<template>
  <div class="about">
    <br />
    <br />
    <div class="calls" v-if="calls && calls.length">
      <h3 class="title is-4">
        Recent calls
        <DotDrop
          :items="[
            {
              t: this.showTableFilter ? 'Hide filters' : 'Show filters',
              icon: 'far fa-filter',
              click: () => {
                this.showTableFilter = !this.showTableFilter;
              },
            },
            { t: 'Refresh', icon: 'far fa-sync', click: loadCalls },
          ]"
        />
      </h3>

      <!-- ---->
      <div class="box">
        <callTable
          :ops="ops"
          :singleOperaion="true"
          :showTools="true"
          :calls="calls"
          :showTableFilter="showTableFilter"
          :visibleCols="visibleCols"
        />

        {{ calls }}
      </div>
    </div>
  </div>
</template>

<script>
//import InvitationModule  from '../../../lib/invitation-module/module.js' ;
// InvitationModule = require('./../lib/invitation-module/module.js');
//any ways to make it available everywhere?
/*
var server = InvitationModule.client;

window.a = server;
window.api = server;
window.e = InvitationModule.endpoints;
*/

//import VueJsonPretty from "vue-json-pretty";
//import "vue-json-pretty/lib/styles.css";
import vueJsonEditor from "vue-json-editor";

//import { Path } from "path-parser";

import DoughnutChart from "@/components/Doughnut.vue";
import DotDrop from "@/components/DotDrop.vue";

import CacheDoughnut from "@/components/CacheDoughnut.vue";
import ChartHoriStack from "@/components/ChartHoriStack.vue";

import MiniStack from "@/components/MiniStack.vue";
import callTable from "@/components/callTable.vue";

import { MethodColors } from "@/common/utils.js";

export default {
  // name: "API-explore",
  components: {
    // Bar,
    //  MiniStack,
    callTable,
    DotDrop,
    //  CacheDoughnut,
    //  DoughnutChart,
    // ChartHoriStack,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    // vueJsonEditor,
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Settings",
  },
  props: {
    op: {},
    ops: [],
    proj: Object, //
  },
  mounted() {
    this.loadCalls();
    this.loadStats();
  },
  methods: {
    loadStats: async function (opt) {
      this.loading = true;
      /*
      window.API.getRemoteServerCacheStats(this.proj.id).then((stats) => {
        this.projStats = stats;
        this.loading = false;
      });
*/
      //alert(1);
      return 1;
    },
    loadCalls: async function (opt) {
      this.loadingCalls = true;
      this.calls = await window.API.getProjectLogs(this.proj.id, { limit: 500, f: this.op.operationId });
      this.loadingCalls = false;
      //alert(1);
      return 1;
    },
    pcnt: function (n) {
      return Math.round(n * 100);
    },
    save: function (event) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.proj;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };
      window.API.editProject(d.id, d).then((proj) => {
        this.posting = false;
        //proj updated!
      });
    },
  },
  data() {
    return {
      showTableFilter: false,
      posting: false,
      projStats: null,
      loading: true,

      calls: [],
      loadingCalls: true,
      //bar test
      data: {
        labels: ["January", "February", "March"],
        datasets: [{ data: [40, 20, 12] }],
      },
      options: {
        responsive: true,
      },
    };
  },
  computed: {
    globalCachability() {
      var t = this.projStats.total;
      var f = t["FAIL"] || 0;
      var d = t["DYNAMIC"] || 0;
      var n = (t.total - (d + f)) / t.total;
      return this.pcnt(n);
    },
    globalHitRate() {
      var t = this.projStats.total;
      var f = t["FAIL"] || 0;
      var d = t["DYNAMIC"] || 0;
      var allCachable = t.total - (d + f);
      var allCached = t["HIT"] || 0;
      var n = allCached / allCachable;
      return this.pcnt(n);
    },
    globalStaleRate() {
      var t = this.projStats.total;
      //var f = t["FAIL"] || 0;
      var s = t["HIT-STALE"] || 0;
      var all = t["HIT"] || 0;
      var n = s / all;
      return this.pcnt(n);
    },

    airtableEditUrl() {
      var p = this.proj;
      var autoAirtable = p.airtableBase ? "https://airtable.com/" + p.airtableBase : null;
      return p.airtableUrl || autoAirtable;
    },
  },
};
</script>
