var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[(_vm.op)?_c('div',{staticClass:"container home"},[_c('nav',{staticClass:"breadcrumb is-xlarge",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',{staticClass:"projectName"},[_c('router-link',{attrs:{"to":{
              path: '/' + _vm.$route.params.project + '',
            }}},[_vm._v(_vm._s(_vm.$route.params.project)+"\n          ")])],1),_vm._v(" "),_c('li',{staticClass:"is-active",on:{"click":function($event){return _vm.copyOp(_vm.$route.params.operation)}}},[_c('b-tooltip',{attrs:{"delay":500,"multilinedNO":"","position":"is-top","type":"is-dark","label":_vm.copied == _vm.$route.params.operation ? 'copied!' : 'copy function name'}},[_c('a',[_vm._v("\n              "+_vm._s(_vm.$route.params.operation)+"\n              "),(_vm.op && _vm.op.emoji)?_c('span',{staticClass:"emoji"},[_vm._v("   "+_vm._s(_vm.op.emoji)+" ")]):_vm._e()])])],1)])]),_vm._v(" "),_c('p',[_c('router-link',{staticStyle:{"float":"left","padding-right":"5px"},attrs:{"to":{
          path: '/' + _vm.$route.params.project + '/endpoints?method=' + 'get',
        },"active-class":"is-active","exact":""}},[_c('b-field',[_c('b-tag',{class:_vm.MethodColors[_vm.op.method],attrs:{"rounded":"","type":" is-light"}},[_vm._v(" Published**")])],1)],1)],1),_vm._v(" "),_c('p',{on:{"click":function($event){return _vm.copyOp(_vm.op.path)}}},[_c('b-tooltip',{attrs:{"delay":500,"multilinedNO":"","position":"is-right","type":"is-dark","label":_vm.copied == _vm.op.path ? 'copied!' : 'copy path'}},[_c('span',{staticStyle:{"opacity":"0.2","margin-right":"10px","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.op.path)+" ")])])],1),_vm._v(" "),_c('br',{staticStyle:{"clear":"both"}}),_vm._v(" "),_c('div',{staticClass:"tabs"},[_c('ul',[_c('router-link',{attrs:{"tag":"li","to":{
            path: '/' + _vm.$route.params.project + '/' + _vm.$route.params.operation,
          },"active-class":"is-active","exact":""}},[_c('a',[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"far fa-play",attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('span',[_vm._v("Preview")])])]),_vm._v(" "),_c('router-link',{attrs:{"tag":"li","to":{
            path: '/' + _vm.$route.params.project + '/' + _vm.$route.params.operation + '/use',
          },"active-class":"is-active","exact":""}},[_c('a',[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"far fa-pencil",attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('span',[_vm._v("Text")])])]),_vm._v(" "),(!_vm.op.isSystem)?_c('router-link',{attrs:{"tag":"li","to":{
            path: '/' + _vm.$route.params.project + '/' + _vm.$route.params.operation + '/code',
          },"active-class":"is-active","exact":""}},[_c('a',[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"far fa-image",attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('span',[_vm._v("Medias")])])]):_vm._e(),_vm._v(" "),(!_vm.op.isSystem)?_c('router-link',{attrs:{"tag":"li","to":{
            path: '/' + _vm.$route.params.project + '/' + _vm.$route.params.operation + '/insights',
          },"active-class":"is-active","exact":""}},[_c('a',[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"far fa-megaphone",attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('span',[_vm._v("Social")])])]):_vm._e(),_vm._v(" "),(!_vm.op.isSystem)?_c('router-link',{attrs:{"tag":"li","to":{
            path: '/' + _vm.$route.params.project + '/' + _vm.$route.params.operation + '/insights',
          },"active-class":"is-active","exact":""}},[_c('a',[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"far fa-analytics",attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('span',[_vm._v("Traffic")])])]):_vm._e(),_vm._v(" "),(!_vm.op.isSystem)?_c('router-link',{attrs:{"tag":"li","to":{
            path: '/' + _vm.$route.params.project + '/' + _vm.$route.params.operation + '/config',
          },"active-class":"is-active","exact":""}},[_c('a',[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"far fa-cogs",attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('span',[_vm._v("Advanced")])])]):_vm._e()],1)]),_vm._v(" "),(_vm.ops && _vm.op && !_vm.loadingOps && !_vm.loadingProj)?_c('div',[_c('router-view',{attrs:{"op":_vm.op,"ops":_vm.ops,"proj":_vm.proj},on:{"specChange":_vm.specChange}})],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }