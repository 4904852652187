<template>
  <div class=" ">
    <!-- --  
    <b-select v-model="selectedServerId" @change="onServerChange">
      <template v-for="(server, idx) in servers">
        <option :key="idx" :value="server.id">{{ server.id }}</option>
      </template>
    </b-select> -->

    <!-- -  @change="onServerChange"  -->

    <b-dropdown v-model="selectedServerId">
      <template v-slot:trigger>
        <b-tooltip
          v-if="selectedServerId"
          :label="String(selectedApiBaseUrl).replace('https://', '')"
          placement="top"
          type="is-dark"
        >
          <b-button
            :label="selectedServerId"
            icon-pack="mdi"
            :key="selectedServerId + 'fds3fsd5'"
            pack="mdi"
            :icon-left="selectedServer.icon"
            icon-right="menu-down"
            size="is-small"
            type="  is-dark"
            rounded
            outlined
          />
        </b-tooltip>
      </template>

      <b-dropdown-item v-for="(server, idx) in servers" :key="idx" :value="server.id">
        {{ server.id }}
      </b-dropdown-item>
    </b-dropdown>

    <!-- -- TPOKEN SELECT !  @change="onTokenChange"  -->
    <b-dropdown v-model="selectedTokenId" aria-role="list">
      <template #trigger>
        <div>
          <b-button
            v-if="selectedTokenId"
            :label="selectedToken.id || selectedToken.name"
            icon-pack="mdi"
            :key="selectedToken.id + 'fdsfsd5' + selectedServerId"
            pack="mdi"
            :icon-left="selectedToken.icon"
            icon-right="menu-down"
            type="  is-dark"
            rounded
            outlined
            size="is-small"
          />
        </div>
      </template>

      <b-dropdown-item
        :value="i.id"
        aria-role="listitem"
        v-for="(i, index) in selectedServer.tokens"
        :key="index + selectedServer.id + 'fdsfsd5'"
      >
        <div class="media">
          <b-icon class="media-left" pack="mdi" icon-pack="mdi" :icon="i.icon"></b-icon>
          <div class="media-content">
            <h3>{{ i.id || i.name }}</h3>
            <small>{{ i.d }}</small>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
    <!--   
    <hr />
    <h1>Token Selector</h1>

    <hr />
    {{ selectedServerId }} +++selectedServerId<br />
    {{ tokens.length }} +++server nb tokens: tokens<br />
    {{ selectedServer }}selectedServer: selectedServer<br />
    <hr />
    {{ selectedTokenId }}===selectedTokenId<br />
    {{ selectedToken }} +++selectedToken<br />

    <hr />
    TOKENS: {{ tokens }}

 

    <b-select v-model="selectedTokenId" @change="onTokenChange">
      <template v-for="(token, idx) in tokens">
        <option :key="selectedServerId + idx" :value="token.id">{{ token.id }}</option>
      </template>
    </b-select>

  


    <b-select v-model="selectedTokenId" @change="onTokenChange">
      <template v-for="(token, idx) in tokens">
        <option :key="selectedServerId + idx" :value="token.id">{{ token.id }}</option>
      </template>
    </b-select>

     -->
  </div>
</template>

<script>
//import TokenStore from "../../libs/tokenStore.js";

//console.log(TokenStore, "TokenStore 32423");

export default {
  props: {
    tokenStore: {
      type: Object,
      required: true,
    },
    prodUrl: {
      type: String,
      //  required: true,
    },
    devUlr: {
      type: String,
      //required: true,
    },
  },
  data() {
    return {
      // tokenStore: new TokenStore(this.projectId),
      selectedServerId: null,
      // selectedServer: null,
      selectedTokenId: null,
      //selectedToken: null,
    };
  },
  mounted() {
    //this.tokenStore = new TokenStore(this.projectId);
    //   this.selectedServer = this.tokenStore.getSelectedServer();
    this.selectedServerId = this.selectedServer.id;
    //token
    // this.selectedToken = this.tokenStore.getSelectedToken();
    this.selectedTokenId = this.selectedToken.id;
  },
  computed: {
    selectedApiBaseUrl: function () {
      var s = this.tokenStore.getSelectedServer();

      return s.url; //this.tokenStore; //debug only.
    },
    selectedServer() {
      return this.tokenStore.getSelectedServer();
    },
    selectedToken() {
      return this.tokenStore.getSelectedToken();
    },
    servers() {
      return this.tokenStore.getServers();
    },

    tokens() {
      return this.tokenStore.getSelectedServerTokenList();

      //return this.tokenStore.getTokens(this.selectedServer);
    },
    summaryObject() {
      return {
        serverId: this.selectedServerId,
        tokenId: this.selectedTokenId,
        //and full obj tooo
        server: this.selectedServer,
        token: this.selectedToken,
      };
    },
  },
  watch: {
    selectedServerId: {
      handler(val) {
        if (!val) return;
        this.tokenStore.setSelectedServer(val);
        //Also set the token to the previously selected one
        this.onServerChange();
      },
      immediate: true,
      deep: true,
    },
    selectedTokenId: {
      handler(val) {
        if (!val) return;
        this.tokenStore.setSelectedToken(this.selectedServerId, val);
        this.onTokenChange();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onServerChange() {
      this.$emit(
        "onServerChange",
        this.summaryObject
        /*{
        selectedServerId: this.selectedServerId,
        selectedTokenId: this.selectedTokenId,
        // selectedToken: this.selectedToken,
      }*/
      );
    },
    onTokenChange() {
      this.$emit("onServerChange", this.summaryObject);
    },
  },
};
</script>
