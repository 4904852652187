//import invitationApi from "../../../invitation/invt-npm-api/src/index.js"; //the lib, no endpoints.
//import invitationApi from "invitation-api/dist/index.js";

// TODO use midrun.

//import { createClient } from "midrun";
import midrun from "midrun";

import _ from "lodash";

var clientInstance = {};

export function createApiInstance(projectId, proj, ops, options) {
  if (!options) options = {};
  ///console.log("11111", options);
  const PLAY_DEFAULTS = {
    saveToken: false, //disable local storage
    debug: true,
    useFetch: true,
    caching: false,
    baseUrl: "https://myapi.com",
    endpoints: [{ path: "/test", operationId: "test" }],
  };
  var opt = _.defaults(PLAY_DEFAULTS, options);
  // console.log(222, options, opt);

  if (opt.baseUrl == PLAY_DEFAULTS.baseUrl) {
    // load the prod one from the project.
    opt.baseUrl = options.baseUrl || proj.apiBaseUrl; //configured in project
  }

  opt.endpoints = ops; //[{ path: "/asfff33", operationId: "test" }];
  var api = new midrun.createClient(opt);
  clientInstance[projectId] = api;
  return api;
}

export function getApiInstance(projectId, proj, ops, options) {
  if (clientInstance && clientInstance[projectId]) return clientInstance[projectId]; //existing instance

  return createApiInstance(projectId, proj, ops, options);
}
