<template>
  <div class="opTable">
    <div>
      <b-field grouped group-multiline> </b-field>

      <b-table
        :data="calls"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-rounded="isPaginationRounded"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        default-sort="user.first_name"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :page-input="hasInput"
        :pagination-order="paginationOrder"
        :page-input-position="inputPosition"
        :debounce-page-input="inputDebounce"
        hoverable
        striped
      >
        <!-- 


"_id":
"63ea8746cf5f688c4d546782",
"ca":
"HIT",
"d":
"2023-02-13T18:53:57.332Z",
"e":
null,
"f":
"airport",
"ip":
"186.99.139.197",
"ms":
37,
"path":
"/w/airport/dad",
"r":
"https://mid.run/",
"s":
36458

      -->
        <b-table-column v-if="!singleOperaion" field="f" width="9" label="function" searchable sortable v-slot="props">
          <router-link
            @click.native="$scrollToTop"
            :to="{
              path: '/' + $route.params.project + '/' + props.row.f + '/logs',
            }"
          >
            <span> {{ props.row.f }}</span>
          </router-link>
        </b-table-column>

        <b-table-column field="p" label="Params" width="20" searchable v-slot="props">
          <span class="tag is-light" v-for="i in props.row.p" :key="i">
            {{ i }}
          </span>
        </b-table-column>

        <b-table-column field="path" label="Path" sortable searchable width="20" v-slot="props">
          {{ props.row.path }}
        </b-table-column>

        <b-table-column field="en" label="node_env" sortable searchable width="5" v-slot="props">
          {{ props.row.en }}
        </b-table-column>

        <b-table-column field="r" label="ref" sortable searchable width="20" v-slot="props">
          {{ props.row.r }}
        </b-table-column>

        <b-table-column field="opt" label="opt" sortable searchable width="20" v-slot="props">
          {{ props.row.opt }}
        </b-table-column>

        <b-table-column field="ms" width="5" label="processing time" sortable v-slot="props">
          <small class="mono code">
            {{ props.row.ms }}
          </small>
        </b-table-column>

        <b-table-column field="s" width="5" label="size" sortable v-slot="props">
          <small class="mono code">
            {{ prettyBytes(props.row.s) }}
            <!-- see invitetaion for better implementaion -->
          </small>
        </b-table-column>

        <b-table-column field="ca" width="5" label="server cache" searchable sortable v-slot="props">
          <small class="mono code">
            {{ props.row.ca }}
          </small>
        </b-table-column>

        <b-table-column field="d" label="date" sortable width="20" searchable v-slot="props">
          <b-tooltip :label="props.row.d" type="is-dark">
            <span>
              {{ timeAgo(props.row.d) }}
            </span>
          </b-tooltip>
        </b-table-column>

        <b-table-column field="d" label="date" sortable width="20" v-slot="props">
          <router-link
            @click.native="$scrollToTop"
            class="button"
            :to="playUrl(props.row.f, props.row.path, props.row.opt)"
          >
            <span> replay</span>
          </router-link>
        </b-table-column>

        <!-- 
        <b-table-column field="d" label="date" sortable width="20" v-slot="props">
          <router-link
            @click.native="$scrollToTop"
            class="button"
            :to="{
              path: '/' + $route.params.project + '/' + props.row.f,
            }"
          >
            <span> Playground</span>
          </router-link>
        </b-table-column>
          -->

        <!--

        <b-table-column field="tag" label="tag" sortable v-slot="props">
          <small class="tt">
            {{ props.row.tag.join(", ") }}
          </small>
        </b-table-column>

        <b-table-column field="specModified" label="modified" sortable centered v-slot="props">
          <small class="tt">
            {{ new Date(props.row.specModified).toLocaleDateString() }}
          </small>
        </b-table-column>

        <b-table-column field="specCreated" label="created" sortable centered v-slot="props">
          <small class="ttt small">
            {{ new Date(props.row.specCreated).toLocaleDateString() }}
          </small>
        </b-table-column>

         -- -->

        <!--
        <b-table-column label="Gender" v-slot="props">
          <span>
            <b-icon
              pack="fas"
              :icon="props.row.gender === 'Male' ? 'mars' : 'venus'"
            >
            </b-icon>
            {{ props.row.gender }}
          </span>
        </b-table-column> -->
      </b-table>
    </div>

    <!-- 
    <router-link :to="{ path: '/a/' + $route.params.project + '/endpoints' }" active-class="is-active" exact>
      <b-button rounded>Show all</b-button>
    </router-link>
     -->
  </div>
</template>

<script>
//import opCard from "../components/opCard.vue";

import MiniStack from "@/components/MiniStack.vue";

import { matchPath, MethodColors, timeAgo } from "@/common/utils.js";

import prettyBytes from "pretty-bytes";

var rolesIcons = {
  public: "fa-minus",
  logged: "fa-user",
  owner: "fa-user-check",
  admin: "fa-lock",
};

var ImplStatusColors = {
  Idea: "is-light",
  Todo: "is-info",
  WIP: "is-warning",
  Stable: "is-success",
  Bug: "is-danger",
};

var ImplStatusIcons = {
  No: "fa-signal-alt-1",
  Idea: "fa-signal-alt-2",
  Todo: "fa-signal-alt-3",
  WIP: "fa-signal-alt ",
  Stable: "fa-check-circle",
  Bug: "fa-bug",
};

export default {
  components: {
    //   MiniStack,
    // opCard,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    // vueJsonEditor,
  },
  props: {
    calls: Array, //looppp
    visibleCols: Object,
    singleOperaion: Boolean,
    showTableFilter: Boolean,
    ops: [],
  },
  mounted() {
    //console.log("mounted", this.calls);
    var arr = this.ops;
    const dict = {};
    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];
      dict[obj.operationId] = obj;
    }

    this.opsDict = dict;
  },
  methods: {
    playUrl: function (operationId, route, opt) {
      var op = this.opsDict[operationId];
      var path = op.path; //  get from SPEC

      console.log("path, route ::", path, route);
      var matchObj = matchPath(path, route);

      if (!matchObj.matches) return;
      console.log("matchObj ::", matchObj);
      /*
     {
  matches: true,
  params: {
    userId: '5'
  }
}*/
      //make query sting from these params
      var query = Object.keys(matchObj.params)
        .map(function (key) {
          return encodeURIComponent(key) + "=" + matchObj.params[key];
          // return encodeURIComponent(key) + "=" + encodeURIComponent(matchObj.params[key]);
        })
        .join("&");

      var projectId = this.$route.params.project;
      var url = "/" + projectId + "/" + operationId + "?" + query;
      //also pass the query params data as an object that can be decoded
      if (opt) url += "&opt=" + encodeURIComponent(JSON.stringify({ opt }));
      return url;
    },

    timeAgo: function (date) {
      return timeAgo(date);
    },
    prettyBytes: function (bytes) {
      return prettyBytes(bytes);
    },
    canShow: function (colSlug) {
      // var visibleCols = this.visibleCols;
      if (!colSlug) return true;
      var colPref = this.visibleCols[colSlug];
      if (colPref === null) return true; //visible by default if unspecefied.
      return colPref; //true false
    },
  },
  data() {
    return {
      MethodColors,
      ImplStatusColors,
      ImplStatusIcons,
      rolesIcons,
      //  data,
      isPaginated: false,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 5,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",

      jsonPostData: 0,
      opsDict: [],
    };
  },
};
</script>
<style>
.opTable a {
  color: black;
}
.opTable a:hover {
  text-decoration: underline;
}

.opTable table th {
  color: #bbb;
  font-weight: normal;
}
</style>
