<template>
  <div class="about">
    <!--   <h3 class="title is-3">Project Settings</h3>

  
    <a :href="proj.airtableUrl" target="_blank">
      <b-button rounded>Manage Endpoints spec</b-button>
    </a>

    <h3>Edit your global API configuration based on your needs.</h3>
    <br />

     -->

    <br />
    <br />

    <h3 class="title is-4">Design endpoints</h3>
    <h3 class="subtitle is-5">Using spreadsheets to design your API is fast, simple & flexible</h3>
    <p>
      Your endpoint properties will be available in your backend functions and middlewares.
      <br />

      You can add custom fields and logic to your endpoints using this Airtable spreadsheet.
      <br />
    </p>

    <br />
    <a :href="airtableEditUrl" v-if="airtableEditUrl" target="_blank">
      <b-button type="is-info is-outlined">Edit in Airtable</b-button>
    </a>

    <b-button type="is-info is-outlined" @click="refreshAirtableEndpoints" :loading="refreshingAirtable"
      >Refresh data from Airtable</b-button
    >

    <b-button type="is-info is-outlined" @click="showSwag = true">Import Swagger (openAPI) spec</b-button>

    <div v-if="showSwag" class="box">
      <b-field label="Swagger Specefication (JSON or YAML)">
        <b-input
          type="textarea"
          placeholder="   path:  /stuff/{stuffId} ...  "
          v-model="swagData"
          :loading="posting"
          :disabled="posting"
        ></b-input>
      </b-field>
      <b-field>
        <b-switch v-model="swagMeta" type="is-success">
          Also import project Matadata
          <b-tooltip type="is-dark" multilined label="You can always edits these later under settings">
            <i class="fal fa-question-circle" aria-hidden="true"></i>
          </b-tooltip>
        </b-switch>
      </b-field>

      <b-button type="is-black  " @click="postSwag = true">Import into project</b-button>
    </div>
    <br />
    <br />
    <br />

    <div v-if="proj.airtableShareViewUrl">
      <h3 class="title is-5">Read-only preview</h3>
      <iframe
        v-if="airtableEditUrl"
        class="airtable-embed"
        :src="
          String(proj.airtableShareViewUrl).replace('https://airtable.com/', 'https://airtable.com/embed/') +
          '?backgroundColor=purple&viewControls=on'
        "
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="600"
        style="background: transparent; border: 1px solid #ccc"
      ></iframe>
    </div>

    <hr />
    <h3 class="title is-4">Export API spec</h3>
    <h3 class="subtitle is-5">Use this API with other tools, playgrounds and documentation builder.</h3>
    <p></p>

    <b-field label="">
      <b-select placeholder="Select format" v-model="specFormat">
        <option
          v-for="i in [
            ['apibros', 'ApiBros JSON'],
            ['swagger2-json', 'Swagger 2 JSON'],
            ['swagger2-yaml', 'Swagger 2 YAML'],
            ['swagger3-json', 'Swagger 3 JSON / OpenApi'],
            ['swagger3-yaml', 'Swagger 3 YAML / OpenApi'],
          ]"
          :value="i[0]"
          :key="i[0]"
        >
          {{ i[1] || i[0] }}
        </option>
      </b-select>
    </b-field>

    <b-button type="is-info is-outlined" @click="downloadSpec">Download</b-button>

    <!--  
    <h3 class="title is-4">Raw JSON</h3>

    <vue-json-editor
      v-if="enableGetOptions || !isGet"
      v-model="proj"
      mode="code"
      :show-btns="false"
      :expandedOnStart="true"
      @json-change="onJsonPostDataChange"
    ></vue-json-editor>

    <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
  --></div>
</template>

<script>
//import InvitationModule  from '../../../lib/invitation-module/module.js' ;
// InvitationModule = require('./../lib/invitation-module/module.js');
//any ways to make it available everywhere?
/*
var server = InvitationModule.client;

window.a = server;
window.api = server;
window.e = InvitationModule.endpoints;
*/

//import VueJsonPretty from "vue-json-pretty";
//import "vue-json-pretty/lib/styles.css";
import vueJsonEditor from "vue-json-editor";

//import { Path } from "path-parser";

import { MethodColors } from "@/common/utils.js";

export default {
  // name: "API-explore",
  components: {
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    //   vueJsonEditor,
  },
  props: {
    proj: Object, //
  },
  methods: {
    downloadSpec: function () {
      window.API.getOpenApiSpec(this.proj.id, { format: this.specFormat }).then((proj) => {
        this.posting = false;
        //proj updated!
      });
    },
    postSwag: function (ev) {
      var p = {
        data: this.swagData,
        updateProjectMeta: this.swagMeta,
      };
      window.API.importOpenApiSpec(p).then((proj) => {
        this.posting = false;
        //proj updated!
      });
    },

    refreshAirtableEndpoints: function () {
      this.refreshingAirtable = true;
      window.API.getProjectOperations(this.proj.id, { noCache: 1, nocache: 1 }).then((ops) => {
        this.refreshingAirtable = false;
        this.$buefy.toast.open({
          message: "Updated spec, refresh this tab please.   ",
          type: "is-success",
        });
      });
    },

    save: function (event) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.proj;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };
      window.API.editProject(d.id, d).then((proj) => {
        this.posting = false;
        //proj updated!
      });
    },
  },
  computed: {
    airtableEditUrl() {
      var p = this.proj;
      var autoAirtable = p.airtableBase ? "https://airtable.com/" + p.airtableBase : null;
      return p.airtableUrl || autoAirtable;
    },
  },
  data() {
    return {
      posting: false,
      showSwag: false,
      swagMeta: true,
      swagData: "",
      specFormat: "apibros",

      refreshingAirtable: false,

      /*
      jsonPostData: {
        slug: "DashboardApi",
        desc: "internal apiBrosApi",
        apiBaseUrl: "https://api.invitation.codes.com/api/v2",
        apiBaseUrlDev: "http://localhost:3000/api/v2",
        avatar:
          "https://cdn11.bigcommerce.com/s-k6q7hvb4ne/images/stencil/1280x1280/products/42374/23193/MB113__80899.1654309088.jpg",

        airtableUrl: "https://airtable.com/appLzzxDJ0Ba8GYhz/tblhZ7rY7fj10uS7N/viw7yE2uQGDbRHIKG", //ref only.

        dashboardDomain: "internal.apibros.io",

        users: [], //redonly
        editors: [], // can edit endpoint and access all tools
        admins: ["felix.menard@gmail.com"], //can edit all settings

        publicAccess: false,

        limit: 2,

        txt: "pizza",
        forceRefresh: "not-in-use",
        locale: "fr",
        noCache: 1,
      },
      errorCode: null,
      enableGetOptions: false,
      tabFilter: 0,
      resActiveTab: 0,
      filterTxt: "",
      formatJsonRes: true,
      MethodColors,
      beLazy: false,
      value: null,
      endpoints: [],
      // endpoint: {},
      response: {},
      responseData: {},
      error: null,
      resUrl: "",
      param2: null,
      param1: null,
      param3: null,
      optTxt: "",
      running: false,
      paramExamples: {
        shortid: "Owc4NhV2y 0nNY2DzNc @felix ".split(" "),
        otherUserID: "Owc4NhV2y 0nNY2DzNc @felix ".split(" "),
        userid: "Owc4NhV2y 0nNY2DzNc @felix ".split(" "),
        handle: "maude ez 000 m".split(" "),
        slug: "airbnb uber google-fi".split(" "),
        tag: "travel crypto pets product".split(" "),
      },*/
    };
  },
};
</script>
<style scoped>
h3.title.is-4 {
  /* sexy */
  font-size: 33px;
  font-weight: 100;
  line-height: 2em;
  margin-top: 0.5em;
}
</style>
