<template>
  <div>
    <div class="card">
      <!-- 
      <div class="card-image">
        <figure class="image is-4by3">
          <img
            src="https://bulma.io/images/placeholders/1280x960.png"
            alt="Placeholder image"
          />
        </figure>
      </div>-->
      <div class="card-content">
        <div class="media">
          <div class="media-left">
            <figure class="image is-16x16">
              <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
            </figure>
          </div>
          <div class="media-content">
            <p class="title is-6">{{ op.operationId }}</p>
            <p class="subtitle is-6">
              <b-tag type="i  is-light" :class="MethodColors[op.method]">{{ op.method }}</b-tag>
              🚧
              <b-tag rounded type="  is-light">Public</b-tag>
            </p>
            <p class=" ">{{ op.path }}</p>
          </div>
        </div>
        <!-- 
        <div class="content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec
          iaculis mauris. <a>@bulmaio</a>. <a href="#">#css</a>
          <a href="#">#responsive</a>
          <br />
          <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
/*
import { MethodColors } from "@/common/utils.js";*/

import { MethodColors } from "@/common/utils.js";

export default {
  name: "HelloWorld",
  props: {
    op: Object,
  },
  data() {
    return {
      MethodColors,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.card-content {
  padding: 9px;
}
</style>
