<template>
  <div class="about">
    <br />
    <div class="columns is-5 is-variable">
      <div class="column is-6">
        <div class="columns">
          <div class="column">
            <h1 class="title is-3">
              Code draft

              <span class="tag">⚡ AI generated</span>
            </h1>
          </div>
          <div class="column is-narrow">
            <b-button
              type="is-dark"
              icon-left="bolt"
              icon-pack="fas"
              @click="getCodeGen"
              style="margin-bottom: 20px"
              :loading="generating"
              font-size="12px"
            >
              {{ op.generatedBackendCode ? " Generate again" : "Generate draft code" }}
            </b-button>
          </div>
        </div>

        <b-message type="is-info" has-iconNO>
          Draft your back-end functions with Midrun AI.<br />
          Give some context to by describing the purpose of your function, and provide some implemetation ideas in the
          devNotes.
          <br />
          Run <code>midrun spec</code> in your project to sync latest drafts.
        </b-message>

        <!--  
        <h1 class="subtitle is-5"></h1>
         -->

        <b-field horizontalNO label="desc">
          <b-input type="textarea" placeholder="This function does something." v-model="op.desc"></b-input>
        </b-field>

        <b-field horizontalNO label="Dev Notes">
          <b-input type="textarea" placeholder="This API knows things" v-model="op.devNotes"></b-input>
        </b-field>

        <b-field horizontalNO label="responseType">
          <b-select v-model="op.responseType" placeholder="This method returns....">
            <option v-for="id in ResponseTypes" :value="id" :key="id">
              {{ id }}
            </option>
          </b-select>
        </b-field>

        <b-button
          type="is-success"
          icon-right="chevron-right"
          icon-pack="fas"
          @click="saveOp"
          style="float: right; margin-top: 20px"
          :loading="generating"
          font-size="12px"
          >SAVE
        </b-button>

        <!-- 
        <CodeEditor
          value="console.log(13)"
          theme="dark"
          class="bro_code"
          :read_only="true"
          :hide_header="false"
          :copy_code="true"
        ></CodeEditor>
        11
 -->
        <hr />
        <h1 class="title is-5">Calling the API</h1>

        <CodeEditor
          v-model="codeBasicCall"
          theme="dark"
          class="bro_code"
          :read_only="true"
          :hide_header="false"
          :copy_code="true"
        ></CodeEditor>
        or
        <CodeEditor
          v-model="codeBasicCallCatch"
          theme="dark"
          class="bro_code"
          :read_only="true"
          :hide_header="false"
          :copy_code="true"
        ></CodeEditor>

        <hr />
        <h1 class="title is-5">Server handler</h1>

        Using good old callbacks
        <CodeEditor
          v-model="codeServerHandler"
          theme="dark"
          class="bro_code"
          :read_only="true"
          :hide_header="false"
          :copy_code="true"
        ></CodeEditor>
        <br />
        or using an async function
        <CodeEditor
          v-model="codeServerAsyncHandler"
          theme="dark"
          class="bro_code"
          :read_only="true"
          :hide_header="false"
          :copy_code="true"
        ></CodeEditor>
        Note: You can can handle error using <code>throw</code> within your function scope:
        <code>throw "my error message"</code>

        <hr />
        <h1 class="title is-5">Endpoint Spec data raw</h1>

        <CodeEditor
          :value="JSON.stringify(op, 2, 2)"
          theme="dark"
          class="bro_code"
          :read_only="true"
          :hide_header="false"
          :copy_code="true"
        ></CodeEditor>

        <!-- 
        <img alt="Vue logo" src="../assets/logo.png" />
        -->
      </div>
      <div class="column">
        <img
          v-if="generating"
          src="https://64.media.tumblr.com/83928a1806eea0bf918c0dff130c9dbc/tumblr_na39k233Xy1r83cqho1_400.gif"
        />

        <CodeEditor
          v-if="op.generatedBackendCode"
          v-model="op.generatedBackendCode"
          theme="dark"
          class="bro_code"
          :read_only="true"
          :hide_header="false"
          :copy_code="true"
          font_size="12px"
        ></CodeEditor>
      </div>
    </div>
  </div>
</template>

<script>
//import InvitationModule  from '../../../lib/invitation-module/module.js' ;
// InvitationModule = require('./../lib/invitation-module/module.js');
//any ways to make it available everywhere?
/*
var server = InvitationModule.client;

window.a = server;
window.api = server;
window.e = InvitationModule.endpoints;
*/

//import VueJsonPretty from "vue-json-pretty";
//import "vue-json-pretty/lib/styles.css";
//import vueJsonEditor from "vue-json-editor";

//import { Path } from "path-parser";

import { MethodColors, ResponseTypes } from "@/common/utils.js";

//import opGrid from "../components/opGrid.vue";
export default {
  // name: "API-explore",
  components: {
    // opGrid,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    // vueJsonEditor,
  },
  props: {
    op: {},
    proj: Object, //
  },
  data() {
    return {
      ResponseTypes,
      jsonPostData: 0,
      generating: false,
      posting: false,
    };
  },
  methods: {
    saveOp: function (event) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.proj;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };
      var op = this.op;
      window.API.editProjectOperation(this.proj.id, op.operationId, op).then((proj) => {
        this.posting = false;
        this.$emit("specChange", this.proj.id);
        //proj updated!
      });
    },
    getCodeGen(opt) {
      //this.loadingOps = true;
      //this.loadingProj = true;
      var p = this.$route.params.project;
      var op = this.op;

      this.generating = true;
      window.API.getCodeGen(p, op.operationId).then((code) => {
        this.generating = false;

        this.op.generatedBackendCode = code;
        this.$emit("specChange", this.proj.id);
      });
    },
  },

  computed: {
    codeParamsInFucntion() {
      var p = this.op;
      if (!p.urlParameters || !p.urlParameters.length) return "";
      var prop = p.urlParameters.join(", ");
      return " " + prop + " ";
    },
    codeBasicCall() {
      var p = this.op;
      return `//in an async function...
const myData = await API.${p.operationId}(${this.codeParamsInFucntion})
//...
console.log(myData);   `;
    },
    codeBasicCallCatch() {
      var p = this.op;
      return `//using promises...
API.${p.operationId}(${this.codeParamsInFucntion})
  .then(myData  => {
     // code to display myData 
  }).catch(e => console.log('Api error: ', e))
   `;
    },

    codeServerHandler() {
      var p = this.op;
      var firstTag = p.tag && p.tag.length ? p.tag[0] : null;
      var grp = p.group || firstTag || "api";
      // var propsParams = p.urlParameters.join(", ");
      var handlerProps = "";
      if (p.urlParameters.length) handlerProps += p.urlParameters.join(", ") + ", ";
      handlerProps += "json"; // last one always?

      var dummyFn = "Call DB";
      if (p.urlParameters.length) dummyFn += " with '" + p.urlParameters.join("' & '") + "'";

      return `//${grp}.js
//...
${p.operationId}: ({ ${handlerProps} }) => {
  //${dummyFn}
  json("Pizza!"); //sends json response to client
},
//...  `;
    },
    codeServerAsyncHandler() {
      var p = this.op;
      var firstTag = p.tag && p.tag.length ? p.tag[0] : null;
      var grp = p.group || firstTag || "api";
      // var propsParams = p.urlParameters.join(", ");
      var handlerProps = "";
      if (p.urlParameters.length) handlerProps += p.urlParameters.join(", "); // + ", ";
      // handlerProps += "send"; // last one always?

      var dummyFn = "await ";
      if (p.urlParameters.length) dummyFn += " with '" + p.urlParameters.join("' & '") + "'";

      return `//${grp}.js
//...
${p.operationId}: async ({ ${handlerProps} }) => { 
  var data = await longDatabaseCall(${handlerProps});
  return data;  
},
//...  `;
    },
  },
};
</script>
