<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 40,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: ["average", "method1", "login"],
        datasets: [
          {
            label: "HIT",
            data: [62, 68, 74, 80, 66, 84],
            backgroundColor: "rgb(0, 178, 169)",
            order: 2,
            stack: "stack1",
          },
          {
            label: "HIT-STALE",
            data: [5, 3, 7, 9, 2, 4],
            backgroundColor: "rgb(207, 16, 45)",
            order: 3,
            stack: "stack1",
          },
          {
            label: "MISS",
            data: [5, 3, 7, 9, 2, 4],
            backgroundColor: "rgb(207, 16, 45)",
            order: 1,
            stack: "stack1",
          },
        ],
        /*
        [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
          },
        ],*/
      },

      // DOCS:
      // https://www.chartjs.org/docs/3.9.1/charts/bar.html
      chartOptions: {
        indexAxis: "y",
        maxBarThickness: 10,
        barThickness: 10,
        borderSkipped: true,
        /*
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
              },
            },
          ],
        },*/
        // clip: 5,
        //  responsive: true,
        //maintainAspectRatio: false,
      },
    };
  },
};
</script>
