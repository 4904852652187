<template>
  <span>
    <!--  {{ statsObj }}

    ----
    {{ values }}
-->

    <b-tooltip :label="tip" multilined type="is-dark">
      <MiniStackVanila :w="250" :colors="defColors" :values="values" />
    </b-tooltip>
  </span>
</template>

<script>
import MiniStackVanila from "@/components/MiniStackVanila.vue";

/*

 :style="{ width: '400px', height: '10px' }"
   
 */
var defColors = ["#1b4e6b", "#5c63a2", "#c068a8", "#ec7176", "#f4ab33", "#ff0000", "#cccccc"];
export default {
  components: {
    MiniStackVanila,
    // opCard,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    // vueJsonEditor,
  },

  props: {
    statsObj: {
      type: Object,
      required: false,
      // default: ["#1b4e6b", "#5c63a2", "#c068a8", "#ec7176", "#f4ab33"],
    },
  },
  data() {
    return {
      defColors,
    };
  },
  computed: {
    tip() {
      return this.statsObj;
    },
    values() {
      /*{ "HIT": 12, "HIT-MEMORY": 12, "HIT-STALE": 11, "HIT-STORE": 0, "MISS": 21, "total": 33 }*/

      var e = this.statsObj;
      var allHit = e["HIT"] || 0;
      var fresh = allHit - e["HIT"] || 0;
      return [fresh, e[" HIT-STALE"] || 0, e["MISS"] || 0, e["DYNAMIC"] || 0, e["FAIL"] || 0];
    },
    col2() {
      return this.colors || defColors;
    },
    sum() {
      var sum = this.values.reduce((a, b) => a + b);
      return sum;
      //viewbox="0 0 66 40"
      // return this.values.reduce((a, b) => a + b);  // Sum of all values
    },
    ratio() {
      return this.w / this.sum;
      //viewbox="0 0 66 40"
      // return this.values.reduce((a, b) => a + b);  // Sum of all values
    },
    viewbox() {
      var sum = this.values.reduce((a, b) => a + b);

      return "0 0 " + sum + " 5";
      //viewbox="0 0 66 40"
      // return this.values.reduce((a, b) => a + b);  // Sum of all values
    },
  },
  methods: {
    calculateX(index) {
      let x = 0;
      for (let i = 0; i < index; i++) {
        x += this.values[i];
      }
      return (x / this.sum) * this.w;

      return x;
    },
  },
};
</script>

<style scoped>
svg {
  background: #ccc;
}
</style>
