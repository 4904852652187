<template>
  <div class="stats">
    <!--   <h3 class="title is-3">Project Settings</h3>

  
    <a :href="proj.airtableUrl" target="_blank">
      <b-button rounded>Manage Endpoints spec</b-button>
    </a>

    <h3>Edit your global API configuration based on your needs.</h3>
    <br />

    
   <h3 class="title is-4">
      Performance
      <DotDrop
        :items="[
          { t: 'Edit default settings', icon: 'far fa-pencil', to: '/settings' },
          { t: 'Refresh', icon: 'far fa-refresh', click: loadStats },
        ]"
      />
    </h3> <b-button type="is-info is-outlined" @click="loadStats" :loading="posting" :disabled="posting">loadStats</b-button>
<ChartHoriStack />
     -->

    <br />
    <br />
    <div class="calls" v-if="calls && calls.length">
      <h3 class="title is-4">
        Recent calls
        <DotDrop
          :items="[
            { t: 'Detailled view', icon: 'far fa-eye', to: `stats/logs` },
            { t: 'Refresh', icon: 'far fa-sync', click: loadCalls },
          ]"
        />
      </h3>

      <!-- ---->
      <div class="box">
        <callTable :showTools="true" :calls="calls" :visibleCols="visibleCols" />

        {{ calls }}
      </div>
    </div>

    <br />
    <br />
    <h3 class="title is-4">
      Server cache
      <DotDrop
        :items="[
          { t: 'Edit default settings', icon: 'far fa-pencil', to: `settings/server` },
          { t: 'Refresh', icon: 'far fa-sync', click: loadStats },
        ]"
      />
    </h3>
    <div class="stats" v-if="!loading">
      <div class="columns" v-if="projStats && projStats.total">
        <div class="column content">
          <h3 class="title is-6">
            Cachability <span class="tag is-success is-light">{{ globalCachability }}%</span>
          </h3>
          <p>
            Most of your trafic is chachable on the server, which means you've properly identified cachable function.
          </p>

          <h3 class="title is-6">
            Hit-rate <span class="tag is-warning is-light">{{ globalHitRate }}%</span>
          </h3>
          <p>Your server cache is rarely hit. Increasing cache durations (especially for stale cache) may help.</p>

          <h3 class="title is-6">
            Stale-rate <span v-if="globalStaleRate" class="tag is-light">{{ globalStaleRate }}%</span>
          </h3>
          <p>
            Stale hits revalidate the cache in the background for the next call.

            <!-- --->
            Those calls consume CPU, but ensure short response time.
          </p>
        </div>
        <div class="column graph">
          <CacheDoughnut
            :hitF="projStats.total.HIT - projStats.total['HIT-STALE']"
            :hitS="projStats.total['HIT-STALE']"
            :miss="projStats.total.MISS"
            :dynamic="projStats.total.DYNAMIC"
            :width="300"
            :height="300"
          />

          <MiniStack :statsObj="projStats.total" :colors="['#1b4e6b', '#5c63a2', '#c068a8', '#ec7176', '#f4ab33']" />
        </div>
      </div>

      <hr />
      <br />
      <!--  
      <DoughnutChart
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="200"
        :height="200"
      />

      <Bar :data="data" :options="options" /> -->

      <h3 class="title is-4">Raw JSON</h3>

      <vue-json-editor
        v-if="enableGetOptions || !isGet"
        v-model="projStats"
        mode="code"
        :show-btns="false"
        :expandedOnStart="true"
        @json-change="onJsonPostDataChange"
      ></vue-json-editor>

      <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
    </div>
  </div>
</template>

<script>
//import InvitationModule  from '../../../lib/invitation-module/module.js' ;
// InvitationModule = require('./../lib/invitation-module/module.js');
//any ways to make it available everywhere?
/*
var server = InvitationModule.client;

window.a = server;
window.api = server;
window.e = InvitationModule.endpoints;
*/

//import VueJsonPretty from "vue-json-pretty";
//import "vue-json-pretty/lib/styles.css";
import vueJsonEditor from "vue-json-editor";

//import { Path } from "path-parser";

import DoughnutChart from "@/components/Doughnut.vue";
import DotDrop from "@/components/DotDrop.vue";

import CacheDoughnut from "@/components/CacheDoughnut.vue";
import ChartHoriStack from "@/components/ChartHoriStack.vue";

import MiniStack from "@/components/MiniStack.vue";
import callTable from "@/components/callTable.vue";

import { MethodColors } from "@/common/utils.js";

export default {
  // name: "API-explore",
  components: {
    // Bar,
    MiniStack,
    callTable,
    DotDrop,
    CacheDoughnut,
    //  DoughnutChart,
    // ChartHoriStack,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    vueJsonEditor,
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Settings",
  },
  props: {
    proj: Object, //
  },
  mounted() {
    this.loadCalls();
    this.loadStats();
  },
  methods: {
    loadStats: async function (opt) {
      this.loading = true;

      window.API.getRemoteServerCacheStats(this.proj.id).then((stats) => {
        this.projStats = stats;
        this.loading = false;
      });

      //alert(1);
      return 1;
    },
    loadCalls: async function (opt) {
      this.loadingCalls = true;
      this.calls = await window.API.getProjectLogs(this.proj.id);
      this.loadingCalls = false;
      //alert(1);
      return 1;
    },
    pcnt: function (n) {
      return Math.round(n * 100);
    },
    save: function (event) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.proj;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };
      window.API.editProject(d.id, d).then((proj) => {
        this.posting = false;
        //proj updated!
      });
    },
  },
  data() {
    return {
      posting: false,
      projStats: null,
      loading: true,

      calls: [],
      loadingCalls: true,
      //bar test
      data: {
        labels: ["January", "February", "March"],
        datasets: [{ data: [40, 20, 12] }],
      },
      options: {
        responsive: true,
      },
    };
  },
  computed: {
    globalCachability() {
      var t = this.projStats.total;
      var f = t["FAIL"] || 0;
      var d = t["DYNAMIC"] || 0;
      var n = (t.total - (d + f)) / t.total;
      return this.pcnt(n);
    },
    globalHitRate() {
      var t = this.projStats.total;
      var f = t["FAIL"] || 0;
      var d = t["DYNAMIC"] || 0;
      var allCachable = t.total - (d + f);
      var allCached = t["HIT"] || 0;
      var n = allCached / allCachable;
      return this.pcnt(n);
    },
    globalStaleRate() {
      var t = this.projStats.total;
      //var f = t["FAIL"] || 0;
      var s = t["HIT-STALE"] || 0;
      var all = t["HIT"] || 0;
      var n = s / all;
      return this.pcnt(n);
    },

    airtableEditUrl() {
      var p = this.proj;
      var autoAirtable = p.airtableBase ? "https://airtable.com/" + p.airtableBase : null;
      return p.airtableUrl || autoAirtable;
    },
  },
};
</script>
<style scoped>
h3.title.is-4 {
  /* sexy */
  font-size: 33px;
  font-weight: 100;
  line-height: 2em;
  margin-top: 0.5em;
}
</style>
