<template>
  <div class="about">
    <!--   <h3 class="title is-3">Project Settings</h3>

  
    <a :href="proj.airtableUrl" target="_blank">
      <b-button rounded>Manage Endpoints spec</b-button>
    </a>

    <h3>Edit your global API configuration based on your needs.</h3>
    <br />

     -->

    <br />
    <br />

    <b-field horizontal>
      <h3 class="title is-4">Server configs</h3>
    </b-field>
    <b-field horizontal>
      <div>
        <h3 class="subtitle is-5">Configure your server, visually.</h3>

        <p>
          Any of these settings can be overridden locally by setting them on your <code>router</code> instance.

          <!-- --  
        </p>
        <p> -->

          Run <code>midrun spec</code> in your server directory to sync these preferences to your
          <code>.midrun</code> file.
        </p>
      </div>
    </b-field>
    <br />

    <hr />

    <div class="headers">
      <b-field horizontal>
        <h3 class="title is-4">Response headers</h3>
      </b-field>

      <b-field horizontal>
        <b-switch size=" e" v-model="proj.serverConfig.browserCache" type="is-info">
          Enable browser cache
          <b-tooltip
            type="is-dark"
            multilined
            label="Cache request responses in memory or persistent storage like Redis"
          >
            <i class="fal fa-question-circle" aria-hidden="true"></i>
          </b-tooltip>
        </b-switch>
      </b-field>
      <div v-if="proj.serverConfig.browserCache">
        <b-field horizontal>
          <div>
            <h3 class="title is-5">Browser cache</h3>
            <p>
              These are global durations for how long ressources are allowed to live in your user's local browser/app
              cache.
              <br />
              You can customize those durations on a per function basis.
            </p>
          </div>
        </b-field>

        <b-field horizontal label=" Max Age ">
          <b-input
            size="is-"
            placeholder="ex: 1000,    5min,  12h, 7d "
            v-model="proj.serverConfig.browserTtl"
            :loading="posting"
            :disabled="posting"
          ></b-input>
        </b-field>

        <b-field horizontal label="  Revalidate ">
          <b-input
            size="is-"
            placeholder="ex: 2600000,  3h,   7d, 2month"
            v-model="proj.serverConfig.browserRevalidate"
            :loading="posting"
            :disabled="posting"
          ></b-input>
        </b-field>

        <b-field horizontal label="    ">
          <p>
            Users will cached ressources at most
            <strong> {{ friendlyDurationStr(proj.serverConfig.browserTtl) }}</strong>
            <span v-if="proj.serverConfig.browserRevalidate">
              <br />
              If data is older than
              <strong> {{ friendlyDurationStr(proj.serverConfig.browserRevalidate) }}</strong>
              <!-- ---->
              , it will be revalidated in background.
            </span>
            <span v-if="msDuration(proj.serverConfig.browserRevalidate) > msDuration(proj.serverConfig.browserTtl)">
              <br />
              ⚠️ Revalidation age (minimum time to stale) has to be smaller that TTL to trigger revalidation. This setup
              will never trigger stale cache revalidation.
            </span>
          </p>
        </b-field>
      </div>

      <hr />

      ---=--90
    </div>

    <div class="serverCache">
      <b-field horizontal>
        <h3 class="title is-4">Server Cache</h3>
      </b-field>

      <b-field horizontal>
        <b-switch v-model="proj.serverConfig.serverCache" type="is-info">
          Enable server cache
          <b-tooltip
            type="is-dark"
            multilined
            label="Cache request responses in memory or persistent storage like Redis"
          >
            <i class="fal fa-question-circle" aria-hidden="true"></i>
          </b-tooltip>
        </b-switch>
      </b-field>
      <div v-if="proj.serverConfig.serverCache">
        <b-field horizontal>
          <div>
            <h3 class="title is-5">Cache Durations</h3>
            <p>
              These are global durations for cachable functions.
              <br />
              You can customize those numbers on a per function basis.
              <br />
              Set value to zero if you only want to server-cache function that excplicitely have cache settings
              configured. (Note that POST requests aren't cached)
            </p>
          </div>
        </b-field>

        <b-field horizontal label=" Max Time to live ">
          <b-input
            size="is-"
            placeholder="ex: 1000,    5min,  12h, 7d, 1year "
            v-model="proj.serverConfig.ttl"
            :loading="posting"
            :disabled="posting"
          ></b-input>
        </b-field>

        <b-field horizontal label="  Revalidate ">
          <b-input
            size="is-"
            placeholder="ex: 200,  5min,   1d"
            v-model="proj.serverConfig.minTimeToStale"
            :loading="posting"
            :disabled="posting"
          ></b-input>
        </b-field>

        <b-field horizontal label="    ">
          <p>
            Responses will be cached on the server at most
            <strong> {{ friendlyDurationStr(proj.serverConfig.ttl) }}</strong>
            <span v-if="proj.serverConfig.minTimeToStale">
              <br />
              If a cache entry older than <strong> {{ friendlyDurationStr(proj.serverConfig.minTimeToStale) }}</strong>
              <!-- ---->
              is acessed, it will be revalidated in background.
            </span>
            <span v-if="msDuration(proj.serverConfig.minTimeToStale) > msDuration(proj.serverConfig.ttl)">
              <br />
              ⚠️ Revalidation age (minimum time to stale) has to be smaller that TTL to trigger revalidation. This setup
              will never trigger stale cache revalidation.
            </span>
          </p>
        </b-field>

        <b-field horizontal>
          <h3 class="title is-5">Memory</h3>
        </b-field>
        <b-field horizontal>
          <b-switch v-model="proj.serverConfig.memoryCache" type="is-info">
            memoryCache
            <b-tooltip
              type="is-dark"
              multilined
              label="Enable memory cache.  Good as a first layer, and for long-running node processes."
            >
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal label="  Max Items  ">
          <b-input
            size="is-"
            placeholder="500 (cached responses)"
            v-model="proj.serverConfig.memoryCacheMaxItems"
            :loading="posting"
            :disabled="posting || !proj.serverConfig.memoryCache"
          ></b-input>
        </b-field>

        <b-field horizontal label="  Max Item site  ">
          <b-input
            size="is-"
            placeholder="2000 (bytes)"
            v-model="proj.serverConfig.memoryCacheMaxSize"
            :loading="posting"
            :disabled="posting || !proj.serverConfig.memoryCache"
          ></b-input>
        </b-field>

        <b-field horizontal>
          <h3 class="title is-5">Persistent</h3>
        </b-field>
        <b-field horizontal>
          <b-switch v-model="proj.serverConfig.redisCache" type="is-info">
            enable redisCache
            <b-tooltip
              type="is-dark"
              multilined
              label="Enable memory cache.  Good as a first layer, and for long-running node processes."
            >
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal label="redisCacheUrl ">
          <b-input
            size="is-"
            placeholder="https://redis.myapp.com/... "
            v-model="proj.serverConfig.redisCacheUrl"
            :loading="posting"
            :disabled="posting || !proj.serverConfig.redisCache"
            type="text"
          ></b-input>
        </b-field>

        <br />

        <b-field horizontal>
          <b-switch v-model="proj.serverConfig.disableCacheInDev" type="is-info">
            disableCacheInDev
            <b-tooltip type="is-dark" multilined label="keep things fresh while developping">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>
      </div>

      <b-field horizontal label="   ">
        <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
          >Save changes</b-button
        >
      </b-field>
    </div>
    <hr />

    <hr />

    <b-field horizontal>
      <h3 class="title is-4">Custom domain</h3>
    </b-field>

    <b-field horizontal label="   ">
      <p>Host this docs on your own domain. Contact us for <code>CNAME</code> setup instructions.</p>
    </b-field>

    <b-field horizontal label="domain name ">
      <b-input
        size="is-"
        placeholder="ex: play.myapp.com"
        v-model="proj.dashboardDomain"
        :loading="posting"
        :disabled="posting"
      ></b-input>
    </b-field>

    <b-field horizontal label="   ">
      <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
    </b-field>

    <hr />

    <h3 class="title is-4">Your settings in JSON</h3>
    <p>You can also copy these settings in your instance if you ever need to eject from this UI dashboard</p>

    <vue-json-editor
      v-if="enableGetOptions || !isGet"
      v-model="proj.serverConfig"
      mode="code"
      :show-btns="false"
      :expandedOnStart="true"
      @json-change="onJsonPostDataChange"
    ></vue-json-editor>

    <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
  </div>
</template>

<script>
//import InvitationModule  from '../../../lib/invitation-module/module.js' ;
// InvitationModule = require('./../lib/invitation-module/module.js');
//any ways to make it available everywhere?
/*
var server = InvitationModule.client;

window.a = server;
window.api = server;
window.e = InvitationModule.endpoints;
*/

//import VueJsonPretty from "vue-json-pretty";
//import "vue-json-pretty/lib/styles.css";
import vueJsonEditor from "vue-json-editor";

//import { Path } from "path-parser";

const humanizeDuration = require("humanize-duration");
const ms = require("ms");

import { MethodColors } from "@/common/utils.js";

export default {
  // name: "API-explore",
  components: {
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    vueJsonEditor,
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "serverConfig",
  },
  props: {
    proj: Object, //
  },
  methods: {
    friendlyDurationStr: function (str) {
      var m = ms(String(str));
      var h = humanizeDuration(m);
      return h;
      // "7 days   " + "10000ms";
    },
    msDuration: function (str) {
      return ms(String(str));
    },
    save: function (event) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.proj;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      // console.log(d);
      const updt = { serverConfig: this.proj.serverConfig };
      // d = { H3334: 343254354354353, id: "dashApi" };
      window.API.editProject(d.id, updt).then((proj) => {
        this.posting = false;
        //proj updated!
      });
    },
  },
  data() {
    return {
      posting: false,
    };
  },
  computed: {
    airtableEditUrl() {
      var p = this.proj;
      var autoAirtable = p.airtableBase ? "https://airtable.com/" + p.airtableBase : null;
      return p.airtableUrl || autoAirtable;
    },
  },
};
</script>
<style scoped>
h3.title.is-4 {
  /* sexy */
  font-size: 33px;
  font-weight: 100;
  line-height: 2em;
  margin-top: 0.5em;
}
</style>
