<template>
  <div class="opGrid">
    <div class="columns is-multiline is-variable is-1">
      <div class="column is-4" v-for="(i, index) in ops" :key="index + 'fd245s'">
        <router-link :to="{ path: '/' + $route.params.project + '/' + i.operationId }" active-class="is-active" exact>
          <opCard :op="i" />
        </router-link>
      </div>
    </div>

    <router-link :to="{ path: '/' + $route.params.project + '/endpoints' }" active-class="is-active" exact>
      <b-button rounded>Show all</b-button>
    </router-link>
  </div>
</template>

<script>
import opCard from "../components/opCard.vue";
export default {
  components: {
    opCard,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    // vueJsonEditor,
  },
  props: {
    ops: Array, //looppp
  },
  data() {
    return {
      jsonPostData: 0,
    };
  },
};
</script>
