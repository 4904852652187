<template>
  <div class="about">
    <br />
    <br />
    <br />
    <!--  
    <h1 class="title is-3">Packages</h1>
    -->
    <div class="content" v-if="proj">
      <h1>Backend</h1>
      <p>
        You can follow Midrun spec on any backend, but the <code>midrun-node</code> module is the easiest way to get a
        powerful server up and running in minutes.
      </p>

      <h2>Install</h2>
      <p>The midrun command line tools simplify things. Install it if you haven't already.</p>
      <CodeEditor
        value="npm i -g midrun-cli"
        theme="dark"
        class="bro_code"
        :read_only="true"
        :hide_header="true"
        :copy_code="true"
        :languages="['bash', 'shell']"
      ></CodeEditor>

      <h3>New project</h3>
      <p>Starting fresh? You can kick-start things with an existing server setup.</p>
      <CodeEditor
        :value="'midrun init ' + proj.id"
        theme="dark"
        class="bro_code"
        :read_only="true"
        :hide_header="true"
        :copy_code="true"
        :languages="['bash', 'shell']"
      ></CodeEditor>

      <p></p>

      <h3>Existing express server</h3>
      <p>Midrun-node is a simple add on.</p>

      <CodeEditor
        :value="'npm i midrun-node \nmidrun link ' + proj.id"
        theme="dark"
        class="bro_code"
        :read_only="true"
        :hide_header="true"
        :copy_code="true"
        :languages="['bash', 'shell']"
      ></CodeEditor>

      <p></p>
      <hr />
      <h2>Staying up to spec</h2>
      <p>
        The <code>midrun spec</code> command will keep your server in sync with your API spec. You can run it as often
        as you like. It will only update your server if there are changes.
      </p>
      <CodeEditor
        value="midrun spec "
        theme="dark"
        class="bro_code"
        :read_only="true"
        :hide_header="true"
        :copy_code="true"
        :languages="['bash', 'shell']"
      ></CodeEditor>

      <hr />

      <h2>Keep in sync</h2>
      <p>
        The <code>midrun spec</code> command will keep your server in sync with your API spec. You can run it as often
        as you like. It will only update your server if there are changes.
      </p>

      <hr />

      <h1>Using the REST API</h1>
      <p>
        The most basic way to access your API is via traditional GET/POST request to your API URLs. No module or SDK
        required
      </p>
      <p>
        Well structured API routes makes it easy to access and update your resources. The endpoint editor let you
        abstract this part from your actual server code so you can prototype your server code while working on your
        naming conventions.
      </p>
      <hr />
      <h1>Javascript Client</h1>
      <p>
        This library streamline intercalations with you API while you work on your front-end. It helps you debug your
        server calls and beautifully surface useful metrics about cache layers and performance.
      </p>
      <h3>Installation</h3>

      <p><code> npm i midrun </code></p>
      <p>Then simply initialize the library and with your latest spec:</p>
      <p>
        <ssh-pre language="js" label="Javascript">
          import midrun from 'midrun';<br />
          import endpoints from 'https://api.mid.run/myApi/all.json';
          <br />
          const options = { <br />
          endpoints, <br />
          caching: true, <br />
          url: 'https://api.mybrand.com/api/v2',<br />
          clientKey: 'webfrontend-dev-12345'<br />
          }<br />
          const myApi = new midrun(options)<br />
          var pizza = await myApi.getPizzas()<br />
          // or...<br />
          myApi.getPizzas() <br />
          .then((pizz) => doSomething(pizz))<br />
          .catch((error) => alert("Pizza call failed..."))
        </ssh-pre>
      </p>
      <p>You'll need to configure your keys on your backend. Midrun don't handle your server secrets.</p>

      <h2>Publishing to NPM</h2>
      <p>
        If some parts of your API is available to the public, it's worth publishing your SDK as an NPM module. Just keep
        in mind you'll have to update this module when your API spec changes.
      </p>
      <hr />

      <h1>Server Middleware</h1>
      <p>
        Way too often, backend end up full of repetitive boilerplate code to handle things like caching rules,
        rate-limiting, role and session validation, parameters parsing, and error handling. Our Express middleware
        abstract all the boring stuff so you can fully focus on the logic.
      </p>
      <p>
        Monitoring and performance data can also be collected via the middleware if you'd like visibility on your
        function performance, caching rates, and bandwidth usage.
      </p>
      <hr />

      <h1>Other languages</h1>
      <b-message type="is-info" has-iconNOO>
        We plan on expanding our SDKs to more languages. <br />Let us know if you'd like to work with us on a specefic
        wrapper.
      </b-message>
    </div>
  </div>
</template>

<script>
import SshPre from "simple-syntax-highlighter";
import "simple-syntax-highlighter/dist/sshpre.css";

export default {
  components: { SshPre },
  props: {
    proj: Object, //
  },
};
</script>
