<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";

import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    hitF: {
      type: Number,
      default: 0,
    },
    hitS: {
      type: Number,
      default: 0,
    },
    miss: {
      type: Number,
      default: 0,
    },
    dynamic: {
      type: Number,
      default: 0,
    },
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    chartData() {
      return {
        labels: ["Fresh Hits", "Stale Hits", "Miss", "Dynamic", "Error"],
        datasets: [
          {
            backgroundColor: ["#1b4e6b", "#5c63a2", "#c068a8", "#ec7176", "#f4ab33"],
            data: [this.hitF, this.hitS, this.miss, this.dynamic, 0],
          },
        ],
      };
    },
  },

  data() {
    return {
      chartDataOld: {
        labels: ["VueJs", "EmberJs", "ReactJs", "AngularJs"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
            data: [40, 20, 80, 10],
          },
        ],
      },
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false,
      },
    };
  },
};
</script>
