<template>
  <div class="playTabb">
    <div class="playTabb">
      <br />
      <!-- empty state if the project has no apiBaseUrl -->

      <div v-if="!proj.apiBaseUrl && !proj.apiBaseUrlDev">
        <div class="empty text-align-center">
          <img
            style="height: 200px"
            src="https://i.pinimg.com/236x/9a/39/a3/9a39a330051522cff8676e516c799b8a--playgrounds-equipment.jpg"
          />
          <h2 class="title is-4">Setup your host</h2>
          <p>Configure your API URL to use the playground. <br />You can use a local host or a remote host.</p>
          <br />
          <router-link :to="{ path: '/' + $route.params.project + '/settings' }" active-class="is-activeNN" exact>
            <b-button type="is-dark">
              <span class="icon is-small"><i class="far fa-cog" aria-hidden="true"></i></span>
              <span>Configure</span>
            </b-button>
          </router-link>
        </div>
      </div>
      <div v-if="proj.apiBaseUrl || proj.apiBaseUrlDev">
        <playgroundTab :op="op" :ops="ops" :proj="proj" />
      </div>
    </div>
  </div>
</template>

<script>
//import InvitationModule  from '../../../lib/invitation-module/module.js' ;
// InvitationModule = require('./../lib/invitation-module/module.js');
//any ways to make it available everywhere?
/*
var server = InvitationModule.client;

window.a = server;
window.api = server;
window.e = InvitationModule.endpoints;
*/

//import VueJsonPretty from "vue-json-pretty";
//import "vue-json-pretty/lib/styles.css";
//import vueJsonEditor from "vue-json-editor";

//import { Path } from "path-parser";

import playgroundTab from "../components/play/playgroundTab.vue";
export default {
  // name: "API-explore",
  components: {
    playgroundTab,
    // opGrid,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    // vueJsonEditor,
  },
  props: {
    op: Object,
    ops: [],
    proj: Object, //
  },
  data() {
    return {
      jsonPostData: 0,
    };
  },
  computed: {
    codeParamsInFucntion() {
      var p = this.op;
      if (!p.urlParameters || !p.urlParameters.length) return "";
      var prop = p.urlParameters.join(", ");
      return " " + prop + " ";
    },
    codeBasicCall() {
      var p = this.op;
      return `//in an async function...
const myData = await API.${p.operationId}(${this.codeParamsInFucntion})
//...
console.log(myData);   `;
    },
    codeBasicCallCatch() {
      var p = this.op;
      return `//using promises...
API.${p.operationId}(${this.codeParamsInFucntion})
  .then(myData  => {
     // code to display myData 
  }).catch(e => console.log('Api error: ', e))
   `;
    },

    codeServerHandler() {
      var p = this.op;
      var firstTag = p.tag && p.tag.length ? p.tag[0] : null;
      var grp = p.group || firstTag || "api";
      // var propsParams = p.urlParameters.join(", ");
      var handlerProps = "";
      if (p.urlParameters.length) handlerProps += p.urlParameters.join(", ") + ", ";
      handlerProps += "json"; // last one always?

      var dummyFn = "Call DB";
      if (p.urlParameters.length) dummyFn += " with '" + p.urlParameters.join("' & '") + "'";

      return `//${grp}.js
//...
${p.operationId}: ({ ${handlerProps} }) => {
  //${dummyFn}
  json("Pizza!"); //sends json response to client
},
//...  `;
    },
    codeServerAsyncHandler() {
      var p = this.op;
      var firstTag = p.tag && p.tag.length ? p.tag[0] : null;
      var grp = p.group || firstTag || "api";
      // var propsParams = p.urlParameters.join(", ");
      var handlerProps = "";
      if (p.urlParameters.length) handlerProps += p.urlParameters.join(", "); // + ", ";
      // handlerProps += "send"; // last one always?

      var dummyFn = "await ";
      if (p.urlParameters.length) dummyFn += " with '" + p.urlParameters.join("' & '") + "'";

      return `//${grp}.js
//...
${p.operationId}: async ({ ${handlerProps} }) => { 
  var data = await longDatabaseCall(${handlerProps});
  return data;  
},
//...  `;
    },
  },
};
</script>
