<template>
  <div class="about">
    <br />
    <br />

    <router-link :to="'/' + proj.id + '/design'">
      <b-button label="back" icon-pack="fal" icon-left="arrow-left" type="is-lighNNt" />
    </router-link>

    <h3 class="title is-4">Import CSV</h3>
    <h3 class="subtitle is-5">Worked on your spec elsewhere? Import it here.</h3>
    <p></p>

    <br />

    <!--

        https://www.npmjs.com/package/vue-csv-import/v/3.3.10
        
    -->
    <div class="columns">
      <div class="column">
        <vue-csv-import
          v-model="parsedCsv"
          :map-fields="['operationId', 'desc', 'method', 'path', 'userCustom']"
          :autoMatchFields="true"
          buttonClass="button is-outlined is-info"
          canIgnore
        >
          <!--  
      <template slot="hasHeaders" slot-scope="{ headers, toggle }">
        <label>
          <input type="checkbox" id="hasHeaders" :value="headers" @change="toggle" />
          Headers?
        </label>
      </template> -->

          <template slot="error"> File type is invalid </template>

          <template slot="thead">
            <tr>
              <th>My Fields</th>
              <th>Column</th>
            </tr>
          </template>
          <!-- 
      <template slot="next" slot-scope="{ load }">
        <button @click.prevent="load">load!</button>
      </template>

      <template slot="submit" slot-scope="{ submit }">
        <button @click.prevent="submit">send!</button>
      </template>
        -->
        </vue-csv-import>
      </div>
      <div class="column" v-if="parsedCsv.length">
        Preview:
        <vue-json-editor
          v-model="parsedCsv"
          mode="code"
          :show-btns="false"
          :expandedOnStart="true"
          @json-change="onJsonPostDataChange"
        ></vue-json-editor>

        <b-button
          @click="clickSave"
          :loading="posting"
          label="Save endpoints spec"
          icon-pack="fas"
          icon-left="SAVE"
          type="is-info"
        />
      </div>
    </div>
    <br />
    <br />
    <br />

    <hr />
  </div>
</template>

<script>
//import InvitationModule  from '../../../lib/invitation-module/module.js' ;
// InvitationModule = require('./../lib/invitation-module/module.js');
//any ways to make it available everywhere?
/*
var server = InvitationModule.client;

window.a = server;
window.api = server;
window.e = InvitationModule.endpoints;
*/

//import VueJsonPretty from "vue-json-pretty";
//import "vue-json-pretty/lib/styles.css";
import vueJsonEditor from "vue-json-editor";

import VueExcelEditor from "vue-excel-editor";
import Vue from "vue";
Vue.use(VueExcelEditor);

import { VueCsvImport } from "vue-csv-import";
// DOCS: https://www.npmjs.com/package/vue-csv-import/v/3.3.9
//import { Path } from "path-parser";

import { MethodColors } from "@/common/utils.js";

export default {
  // name: "API-explore",
  components: {
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    vueJsonEditor,
    VueCsvImport,
    // VueExcelEditor,
  },
  props: {
    proj: Object, //
    ops: [],
  },
  methods: {
    clickSave() {
      alert("saving");
      this.posting = true;
      window.API.getOpenApiSpec(this.proj.id, { format: this.specFormat }).then((proj) => {
        this.posting = false;
        //proj updated!
      });
    },
    exportAsExcel() {
      const format = "xlsx";
      const exportSelectedOnly = false;
      const filename = "test";
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename);
    },
    exportAsCsv() {
      const format = "csv";
      const exportSelectedOnly = false;
      const filename = this.proj.id + "-midrun-spec";
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename);
    },
    newRecord() {
      const rec = {
        user: "nm",
        name: "Norman Morris",
        phone: "1-222-3333333",
        gender: "M",
        age: 28,
        birth: "1993-05-16",
      };
      // Call this to new record
      this.$refs.grid.newRecord(rec);
    },
    goOpPage: function (content, record) {
      this.$router.push(`/${this.proj.id}/${record.operationId}`);
    },

    downloadSpec: function () {
      window.API.getOpenApiSpec(this.proj.id, { format: this.specFormat }).then((proj) => {
        this.posting = false;
        //proj updated!
      });
    },
    postSwag: function (ev) {
      var p = {
        data: this.swagData,
        updateProjectMeta: this.swagMeta,
      };
      window.API.importOpenApiSpec(p).then((proj) => {
        this.posting = false;
        //proj updated!
      });
    },

    refreshAirtableEndpoints: function () {
      this.refreshingAirtable = true;
      window.API.getProjectOperations(this.proj.id, { noCache: 1, nocache: 1 }).then((ops) => {
        this.refreshingAirtable = false;
        this.$buefy.toast.open({
          message: "Updated spec, refresh this tab please.   ",
          type: "is-success",
        });
      });
    },

    save: function (event) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.proj;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };
      window.API.editProject(d.id, d).then((proj) => {
        this.posting = false;
        //proj updated!
      });
    },
  },
  computed: {
    airtableEditUrl() {
      var p = this.proj;
      var autoAirtable = p.airtableBase ? "https://airtable.com/" + p.airtableBase : null;
      return p.airtableUrl || autoAirtable;
    },
  },
  data() {
    return {
      parsedCsv: [],
      posting: false,
      showSwag: false,
      swagMeta: true,
      swagData: "",
      specFormat: "midrun",

      refreshingAirtable: false,

      /*
      jsonPostData: {
        slug: "DashboardApi",
        desc: "internal apiBrosApi",
        apiBaseUrl: "https://api.invitation.codes.com/api/v2",
        apiBaseUrlDev: "http://localhost:3000/api/v2",
        avatar:
          "https://cdn11.bigcommerce.com/s-k6q7hvb4ne/images/stencil/1280x1280/products/42374/23193/MB113__80899.1654309088.jpg",

        airtableUrl: "https://airtable.com/appLzzxDJ0Ba8GYhz/tblhZ7rY7fj10uS7N/viw7yE2uQGDbRHIKG", //ref only.

        dashboardDomain: "internal.apibros.io",

        users: [], //redonly
        editors: [], // can edit endpoint and access all tools
        admins: ["felix.menard@gmail.com"], //can edit all settings

        publicAccess: false,

        limit: 2,

        txt: "pizza",
        forceRefresh: "not-in-use",
        locale: "fr",
        noCache: 1,
      },
      errorCode: null,
      enableGetOptions: false,
      tabFilter: 0,
      resActiveTab: 0,
      filterTxt: "",
      formatJsonRes: true,
      MethodColors,
      beLazy: false,
      value: null,
      endpoints: [],
      // endpoint: {},
      response: {},
      responseData: {},
      error: null,
      resUrl: "",
      param2: null,
      param1: null,
      param3: null,
      optTxt: "",
      running: false,
      paramExamples: {
        shortid: "Owc4NhV2y 0nNY2DzNc @felix ".split(" "),
        otherUserID: "Owc4NhV2y 0nNY2DzNc @felix ".split(" "),
        userid: "Owc4NhV2y 0nNY2DzNc @felix ".split(" "),
        handle: "maude ez 000 m".split(" "),
        slug: "airbnb uber google-fi".split(" "),
        tag: "travel crypto pets product".split(" "),
      },*/
    };
  },
};
</script>
<style scoped>
h3.title.is-4 {
  /* sexy */
  font-size: 33px;
  font-weight: 100;
  line-height: 2em;
  margin-top: 0.5em;
}
</style>
