<template>
  <div class="about">
    <br />
    <br />
    <br />
    <!--  
    <h1 class="title is-3">Packages</h1>
    -->
    <div class="content" v-if="proj">
      <h1>Midrun cloud</h1>
      <p>Deploy your API to the cloud with a single command.</p>
      <p>It's free.</p>
      <span class="tag is-info is-light"> Currently available to selected users only </span>

      <CodeEditor
        value="midrun deploy"
        theme="dark"
        class="bro_code"
        :read_only="true"
        :hide_header="true"
        :copy_code="true"
        :languages="['bash', 'shell']"
      ></CodeEditor>

      <hr />

      <h1>Other hosting options</h1>

      <h2>Dev environments</h2>

      <h3>Github Codespace</h3>

      <p>
        This neat service povides you with a fully working coding interface. There is no need to push your code. Your
        API code is just available as your write it.
      </p>
      <p>
        It's perfect for developers who want to work on their API while on the go,
        <!-- ---->
        or need some serious server ressources (Github codespaces can be configured with generous CPU and RAM
        allocation) .
      </p>
      <p>You can let your collegues test requests on your dev environment while you code.</p>

      <hr />
      <h2>App hosting</h2>
      <p>
        Many provider let you host your API as a web app. You can use the same provider as your frontend app, or choose
        a different one.
      </p>
      <p>
        Those providers integrate nicely with git and will take care of deploying your code each time you push to your
        git repository. You can also use a CI/CD tool like Github Actions or Gitlab CI/CD.
      </p>

      <h4>Serverless or not</h4>
      <p>
        You'll have to decide if you want to host your API serverless (lambda functions), or throught a long-polling
        process.
      </p>

      <p>
        Midrun cloud is using a serverless approach. If it's working well for your API, you can stick to this
        straightforward architechture.
      </p>
      <p>
        The main reason to choose a serverless approach is to save money. You'll only pay for the time your API is used.
      </p>
      <p>Long-polling servers have a fixed cost, but many affordable options are also available.</p>

      <p>
        A big advantage of the traditional long-polling server approach is that there is no <b>cold start time</b>. If
        your serer need to load several libraries uppon start, it will take a few seconds to be ready to handle.
        Serverless functions need to load their libraries each time they are called, which can take a few seconds.
      </p>

      <p>
        Caching is also a big advantage of long-polling servers. If you have a lot of requests, you can cache the
        results directly on your server without having to pay and setup a separate cache system like Redis.
      </p>

      <h4>Serverless providers</h4>
      <ul>
        <li><a href="https://vercel.com/">Vercel</a></li>
        <li><a href="https://www.netlify.com/">Netlify</a></li>
        <li><a href="https://www.digitalocean.com/products/functions/">Digital Ocean function</a></li>
        <li><a href="https://www.cloudflare.com/products/cloudflare-workers/">Cloudflare workers</a></li>
        <li><a href="https://deno.com/deploy">Deno</a></li>
      </ul>

      <h4>Long-polling providers</h4>
      <ul>
        <li><a href="https://www.render.com/">Render</a> (free tier available)</li>
        <li><a href="https://www.heroku.com/">Heroku</a></li>

        <li><a href="https://www.digitalocean.com/products/app-platform/">Digital Ocean App Platform</a></li>
      </ul>

      <hr />
      <h2>DIY servers</h2>

      <p>
        If you have multiple services to host, especially long-polling servers, you might want to consider hosting your
        own. It's much simpler than it sounds.
      </p>
      <p>
        At Midrun, we love dokku. It's a simple and powerful tool to configure various apps on your own servers. It's
        similar to previously mentionned cloud companies, but it's 100% open-source and free to use.
      </p>

      <p>
        Digital Ocean has many affordable server options, starting at $7/m. It's easy to monitor ressource usage on
        Digital Ocean using their visual dashboard.
      </p>
    </div>
  </div>
</template>

<script>
import SshPre from "simple-syntax-highlighter";
import "simple-syntax-highlighter/dist/sshpre.css";

export default {
  components: {
    //  SshPre
  },
  props: {
    proj: Object, //
  },
};
</script>
