<template>
  <div class="container home">
    <div class="columns">
      <div class="column is-3">
        <h1 class="title is-3">New Project</h1>
        <h1 class="subtitle is-5"></h1>
        <!--   
          <img alt="Vue logo" src="../assets/logo.png" />-->
      </div>
      <div class="column">
        <b-field label="Unique Id (case sensitive, no space or special charachters)" style="max-width: 400px">
          <b-input
            size="is-large"
            placeholder="ex: realFakeDoorsApi"
            v-model="p.id"
            :loading="posting"
            :disabled="posting"
          ></b-input>
        </b-field>

        <b-button rounded @click="submit" :loading="posting" :disabled="posting">Create project</b-button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import projCard from "@/components/projCard.vue";
export default {
  name: "Home",
  components: {
    //  HelloWorld,
    //  projCard,
  },
  data() {
    return {
      p: {},
      //   projs: [],
      posting: false,
      // loadingOps: true,
    };
  },
  methods: {
    submit() {
      // Send data to the server or update your stores and such.
      this.posting = true;
      window.API.createProject(this.p)
        .then((proj) => {
          this.posting = false;
          //proj updated!
        })
        .then((succ) => {
          this.$router.push("/" + this.p.id);
        })
        .catch((err) => {
          this.posting = false;
          this.$buefy.toast.open({
            message: "Error creating project. The name might be taken.",
            type: "is-danger",
          });
        });
    },
  },
  mounted() {
    /*
    window.API.getPublicProjects().then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
    /*
      window.API.getProjectOperations(this.$route.params.project).then((ops) => {
        var opId = this.$route.params.operation;
        this.ops = ops;
        this.loadingOps = false;
        this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton
      });*/
  },
};
</script>
