<template>
  <div class="about">
    <br />
    <br />
    <div class="columns is-5 is-variable">
      <div class="column">
        <b-field horizontal>
          <h1 class="title is-3">General</h1>
        </b-field>
        <b-field horizontal label="operationId ">
          <b-input
            size="is-"
            placeholder="https://myapp.com/img/logo-api.png"
            v-model="op.operationId"
            :loading="posting"
            :disabled="true"
            type="url"
          ></b-input>
        </b-field>

        <b-field horizontal label="method">
          <b-select v-model="op.method">
            <option v-for="i in 'GET POST PUT DELETE PATCH'.split(' ')" :value="i" :key="i">
              {{ i }}
            </option></b-select
          >
        </b-field>

        <b-field horizontal label="path ">
          <b-input
            size="is-"
            placeholder="https://myapp.com/img/logo-api.png"
            v-model="op.path"
            :loading="posting"
            :disabled="posting"
            type="text"
          ></b-input>
        </b-field>

        <b-field horizontal label="responseType">
          <b-select v-model="op.responseType">
            <option v-for="i in ResponseTypes" :value="i" :key="i">
              {{ i }}
            </option></b-select
          >
        </b-field>

        <br />
        <b-field horizontal label="   ">
          <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
            >Save changes</b-button
          >
        </b-field>

        <br />

        <hr />
        <!-- 
        <br />
        <h1 class="subtitle is-5">
          {{ op.desc }}

          <span v-if="op.responseType">
            <br />
            <br />
            Returns a <code>{{ op.responseType }}</code>
          </span>
        </h1>

        <p>
          {{ op.devNotes }}
        </p>

        <router-link
          :to="{
            path: '/' + $route.params.project + '/' + $route.params.operation + '/config',
          }"
        >
          <b-button label="Edit endpoint" icon-pack="fal" icon-left="pencil" type="is-lighNNt" />
        </router-link>

  
        <CodeEditor
          value="console.log(13)"
          theme="dark"
          class="bro_code"
          :read_only="true"
          :hide_header="false"
          :copy_code="true"
        ></CodeEditor>
        11
 -->
      </div>
      <div class="column is-narrow">
        <CodeEditor
          :value="JSON.stringify(op, 2, 2)"
          theme="light"
          class="bro_code"
          :read_only="true"
          :hide_header="false"
          :copy_code="true"
          font_size="12px"
        ></CodeEditor>

        <div class="box">
          <h1 class="title is-5">-</h1>
          ----
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import InvitationModule  from '../../../lib/invitation-module/module.js' ;
// InvitationModule = require('./../lib/invitation-module/module.js');
//any ways to make it available everywhere?
/*
var server = InvitationModule.client;

window.a = server;
window.api = server;
window.e = InvitationModule.endpoints;
*/

//import VueJsonPretty from "vue-json-pretty";
//import "vue-json-pretty/lib/styles.css";
//import vueJsonEditor from "vue-json-editor";

//import { Path } from "path-parser";
import { MethodColors, ResponseTypes } from "@/common/utils.js";
//import opGrid from "../components/opGrid.vue";
export default {
  // name: "API-explore",
  components: {
    // opGrid,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    // vueJsonEditor,
  },
  props: {
    op: {},
    proj: Object, //
  },
  data() {
    return {
      ResponseTypes,
      jsonPostData: 0,
      generating: false,
      posting: false,
    };
  },
  methods: {
    save: function (event) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.proj;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };
      var op = this.op;
      window.API.editProjectOperation(this.proj.id, op.operationId, op).then((proj) => {
        this.posting = false;
        this.$emit("specChange", this.proj.id);
        //proj updated!
      });
    },
    getCodeGen(opt) {
      //this.loadingOps = true;
      //this.loadingProj = true;
      var p = this.$route.params.project;
      var op = this.op;

      this.generating = true;
      window.API.getCodeGen(p, op.operationId).then((code) => {
        this.generating = false;

        this.op.generatedBackendCode = code;
        this.$emit("specChange", this.proj.id);
      });
    },
  },

  computed: {
    codeParamsInFucntion() {
      var p = this.op;
      if (!p.urlParameters || !p.urlParameters.length) return "";
      var prop = p.urlParameters.join(", ");
      return " " + prop + " ";
    },
    codeBasicCall() {
      var p = this.op;
      return `//in an async function...
const myData = await API.${p.operationId}(${this.codeParamsInFucntion})
//...
console.log(myData);   `;
    },
    codeBasicCallCatch() {
      var p = this.op;
      return `//using promises...
API.${p.operationId}(${this.codeParamsInFucntion})
  .then(myData  => {
     // code to display myData
  }).catch(e => console.log('Api error: ', e))
   `;
    },

    codeServerHandler() {
      var p = this.op;
      var firstTag = p.tag && p.tag.length ? p.tag[0] : null;
      var grp = p.group || firstTag || "api";
      // var propsParams = p.urlParameters.join(", ");
      var handlerProps = "";
      if (p.urlParameters.length) handlerProps += p.urlParameters.join(", ") + ", ";
      handlerProps += "json"; // last one always?

      var dummyFn = "Call DB";
      if (p.urlParameters.length) dummyFn += " with '" + p.urlParameters.join("' & '") + "'";

      return `//${grp}.js
//...
${p.operationId}: ({ ${handlerProps} }) => {
  //${dummyFn}
  json("Pizza!"); //sends json response to client
},
//...  `;
    },
    codeServerAsyncHandler() {
      var p = this.op;
      var firstTag = p.tag && p.tag.length ? p.tag[0] : null;
      var grp = p.group || firstTag || "api";
      // var propsParams = p.urlParameters.join(", ");
      var handlerProps = "";
      if (p.urlParameters.length) handlerProps += p.urlParameters.join(", "); // + ", ";
      // handlerProps += "send"; // last one always?

      var dummyFn = "await ";
      if (p.urlParameters.length) dummyFn += " with '" + p.urlParameters.join("' & '") + "'";

      return `//${grp}.js
//...
${p.operationId}: async ({ ${handlerProps} }) => {
  var data = await longDatabaseCall(${handlerProps});
  return data;
},
//...  `;
    },
  },
};
</script>
