var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.calls && _vm.calls.length)?_c('div',{staticClass:"calls"},[_c('h3',{staticClass:"title is-4"},[_vm._v("\n      Recent calls\n      "),_c('DotDrop',{attrs:{"items":[
          {
            t: this.showTableFilter ? 'Hide filters' : 'Show filters',
            icon: 'far fa-filter',
            click: () => {
              this.showTableFilter = !this.showTableFilter;
            },
          },
          { t: 'Refresh', icon: 'far fa-sync', click: _vm.loadCalls },
        ]}})],1),_vm._v(" "),_c('div',{staticClass:"box"},[_c('callTable',{attrs:{"ops":_vm.ops,"singleOperaion":true,"showTools":true,"calls":_vm.calls,"showTableFilter":_vm.showTableFilter,"visibleCols":_vm.visibleCols}}),_vm._v("\n\n      "+_vm._s(_vm.calls)+"\n    ")],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }