<template>
  <div class="about">
    <br />

    <!-- -- 
    <h3 class="title is-4">Design endpoints</h3>
    <h3 class="subtitle is-5">Using a spreadsheet to design your API is fast, simple & flexible</h3>

    <p>
      Your endpoint properties will be available in your backend functions and middlewares.
      <br />

      You can add custom fields and logic to your endpoints using this spreadsheet.
      <br />
    </p>  -->

    <h3 class="title is-4">
      Design endpoints
      <DotDrop
        :items="[
          { t: 'Import CSV', icon: 'far fa-file-csv', to: `design/import` },
          //  { t: 'Download CSV', icon: 'far fa-download', click: exportAsCsv },
          { t: 'Export...', icon: 'far fa-file-csv', to: `design/export` },
        ]"
      />
    </h3>

    <!-- 

          <router-link to="design/import">
      <b-button label="Import CSV" icon-pack="fal" icon-left="file-csv" type="is-lighNNt" />
    </router-link>

    <b-button
      label="Download CSV"
      @click="exportAsCsv"
      icon-pack="mdi"
      pack="mdi"
      icon-left="download"
      type="is-lighNNt"
      style="float: right"
    /> -->

    <div v-if="!loading">
      <div style="">
        <!-- 
        <ag-grid-vue
          style="width: 500px; height: 200px"
          class="ag-theme-alpine"
          :columnDefs="columnDefs"
          :rowData="rowData"
        >
        </ag-grid-vue> -->

        <!--   NOTES:
        It really need a hardcoded pixel heigh.... even if exaple comes with % 
    investigage.... 

    rowData

    https://www.ag-grid.com/vue-data-grid/clipboard/



-->

        <b-button
          label="Save changes"
          @click="saveGridData"
          :loading="saving"
          icon-pack="fas"
          icon-left="save"
          type="is-dark"
        />

        <ag-grid-vue
          class="ag-theme-alpine"
          :columnDefs="columnDefs"
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          :enableRangeSelection="true"
          :copyHeadersToClipboard="false"
          :rowData="rawOps"
          :enableFillHandle="true"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="true"
          :enableCellChangeFlash="true"
          domLayout="autoHeight"
          :components="{ checkboxRenderer: CheckboxCellRenderer }"
        ></ag-grid-vue>

        <!--  
               style="width: 100%; height: 500px"


        <b-button
          label="new data"
          @click="extractNewData"
          icon-pack="mdi"
          pack="mdi"
          icon-left="download"
          type="is-lighNNt"
        />
               
        -->

        <b-button
          label="Add 1 empty row"
          @click="addRow(1)"
          icon-pack="mdi"
          pack="mdi"
          icon-left="plus"
          type="is-lighNNt"
        />

        <b-button
          label="10 emptry row"
          @click="addRow(10)"
          icon-pack="mdi"
          pack="mdi"
          icon-left="plus"
          type="is-lighNNt"
        />

        <hr />

        <!-- --   
        NEW:
        {{ newData }}
        <hr />
        OLD:
        <hr />
        {{ rawOps }}
        -->
      </div>
    </div>

    <br />

    <!-- 
    

    <br />

    
        -->

    <vue-json-editor v-model="rawOps" mode="code" :show-btns="false" :expandedOnStart="true"></vue-json-editor>

    <hr />
    <h3 class="title is-4">Endpoint Ideas</h3>
    <h3 class="subtitle is-5">Let Midrun AI expand your API endpoints.</h3>
    <p></p>

    <b-field label="Prompt">
      <b-input
        type="textarea"
        v-model="prompt"
        placeholder="Add endpoints to generate and validate a one-time password for authentication"
      ></b-input>
    </b-field>

    <b-button label="Generate" @click="generateEndpoints" icon-pack="fas" icon-left="bolt" type="is-dark" />

    <div v-if="ideas && ideas.length">
      <ag-grid-vue
        class="ag-theme-alpine"
        :columnDefs="columnDefsIdeas"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef"
        :enableRangeSelection="true"
        :copyHeadersToClipboard="false"
        :rowData="ideas"
        :enableFillHandle="true"
        :undoRedoCellEditing="true"
        :undoRedoCellEditingLimit="true"
        :enableCellChangeFlash="true"
        domLayout="autoHeight"
        :components="{ checkboxRenderer: CheckboxCellRenderer }"
      ></ag-grid-vue>
      <!-- 
             
                 :frameworkComponents="{ checkboxRenderer: CheckboxCellRenderer }" 
      -->
    </div>
    <CheckboxCellRenderer></CheckboxCellRenderer>

    <!--  
    <h3 class="title is-4">Raw JSON</h3>

    <vue-json-editor
      v-if="enableGetOptions || !isGet"
      v-model="proj"
      mode="code"
      :show-btns="false"
      :expandedOnStart="true"
      @json-change="onJsonPostDataChange"
    ></vue-json-editor>

    <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting">Save changes</b-button>
  --></div>
</template>

<script>
//import InvitationModule  from '../../../lib/invitation-module/module.js' ;
// InvitationModule = require('./../lib/invitation-module/module.js');
//any ways to make it available everywhere?
/*
var server = InvitationModule.client;

window.a = server;
window.api = server;
window.e = InvitationModule.endpoints;
*/

import DotDrop from "@/components/DotDrop.vue";

//import VueJsonPretty from "vue-json-pretty";
//import "vue-json-pretty/lib/styles.css";
import vueJsonEditor from "vue-json-editor";
import _ from "lodash";
//import VueExcelEditor from "vue-excel-editor";
//import Vue from "vue";
//Vue.use(VueExcelEditor);
//import { Path } from "path-parser";

// https://www.ag-grid.com/javascript-data-grid/component-cell-editor/#cell-editing-example

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
//npm i @ag-grid-community/client-side-row-model @ag-grid-community/core @ag-grid-community/styles
//npm i @ag-grid-community/vue @ag-grid-enterprise/clipboard @ag-grid-enterprise/menu @ag-grid-enterprise/range-selection
import { AgGridVue } from "@ag-grid-community/vue";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";

//my custom component for checkbox:

//import CheckboxCellRenderer from "@/plugins/ag-grid-checkbox-cell-renderer.js";
import CheckboxCellRenderer from "@/plugins/ag-grid-checkbox-cell-renderer";
//https://plnkr.co/edit/KIlOopnaJUrg0c7A?preview
// https://stackoverflow.com/questions/41706848/how-to-use-a-checkbox-for-a-boolean-data-with-ag-grid

const ideaFields = "operationId desc path method".split(" ");
const columnDefs = [
  { field: "operationId", minWidth: 150, pinned: "left" },
  //   { field: "method" },
  {
    field: "method",
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["GET", "POST", "PUT", "DELETE", "PATCH"],
    },
    // ...other props
  },

  { field: "path", minWidth: 300 },
  // { field: "desc" },
  { field: "emoji", minWidth: 80 },
  {
    field: "desc",
    minWidth: 300,
    editable: true,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      maxLength: "3000", // override the editor defaults
      cols: "50",
      rows: "6",
    },
  },
  //  { field: "responseType", minWidth: 150 },
  {
    field: "responseType",
    minWidth: 120,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: "Array Object String Number Boolean File Redirect".split(" "),
    },
    // ...other props
  },

  { field: "loggedRequired", minWidth: 150, cellRenderer: "checkboxRenderer" },
  // cellRenderer: "checkboxRenderer" - not working...

  // checkboxSelection: true to select entire row
  { field: "ownerRequired" },
  { field: "adminRequired" },

  { field: "contentType" },

  { field: "maxAgeClient" },
  { field: "maxAgeServer" },
  { field: "maxAgeCdn" },
  { field: "staleAgeClient" },
  { field: "staleAgeServer" },
  { field: "staleAgeCdn" },

  { field: "timeout" },
  { field: "monitoring" },
  { field: "npm" },
  // { field: "devNotes" },

  {
    field: "devNotes",
    editable: true,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      maxLength: "900", // override the editor defaults
      cols: "50",
      rows: "6",
    },
  },

  /*
        { field: "bronze" },
        { field: "total" },*/
];

var columnDefsIdeas = columnDefs.filter((i) => ideaFields.includes(i.field));

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  // MenuModule, //pro
  RangeSelectionModule, //pro
  ClipboardModule,
]);

import { MethodColors } from "@/common/utils.js";

export default {
  // // name: "API-explore",
  components: {
    DotDrop,
    "ag-grid-vue": AgGridVue,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    vueJsonEditor,
    CheckboxCellRenderer,
    // VueExcelEditor,
  },
  props: {
    proj: Object, //
    ops: [],
  },

  mounted() {
    this.getRawOps();
  },
  methods: {
    generateEndpoints(e) {
      var p = this.prompt;
      alert(p);
    },

    addRow(nb) {
      for (let i = 0; i < nb; i++) {
        this.rawOps.push({});
      }
    },
    extractNewData() {
      //get data out of grid...
      // Just to validate + select some rows.
      var ar = [];
      // iterate through every node in the grid
      this.gridApi.forEachNode((rowNode, index) => {
        ar.push(rowNode.data);
        console.log("node " + rowNode.data.operationId + " is in the grid");
      });
      this.newData = _.clone(ar);
      console.log(ar, "ARR LOG5464564654G!");
      //this.newData = 12345;
      //  this.rawOps = [123];
      return true;
    },
    async saveGridData() {
      // alert("saveGridData!");
      var d = this.rawOps;
      this.saving = true;
      // TODO: filter empty operations IDS here?
      var up = await window.API.editManyProjectOperations(this.proj.id, {
        ops: d,
      });
      this.$emit("specChange", this.proj.id);
      this.$buefy.toast.open({
        message: "Updated!  Run *midrun spec* to sync those defenitions.   ",
        type: "is-success",
      });
      this.saving = false;

      // alert("DONE! SAVIED");
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      const updateData = (data) => params.api.setRowData(data);
      /*
      fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
        .then((resp) => resp.json())
        .then((data) => updateData(data));*/
    },

    async getRawOps() {
      this.loading = true;
      this.rawOps = await window.API.getProjectOperations(this.proj.id, {
        raw: "1",
        noCache: true,
      });
      this.loading = false;
    },
    /*
    updateGrid(rec, all) {
      //called on updated.
      console.log(23432423, rec, all);
      var rowId = rec[0].$id;
      console.log(rowId);
      var rowData = this.rawOps.filter((i) => i.$id == rowId)[0];
      var updt = _.clone(rowData);
      console.log(updt, "DADA");
      delete updt.$id;
      console.log(updt, "DADA");
      window.API.editProjectOperation(this.proj.id, updt.operationId, updt).then((e) => {
        this.$emit("specChange", this.proj.id);
      });
    },*/

    goOpPage: function (content, record) {
      this.$router.push(`/${this.proj.id}/${record.operationId}`);
    },

    refreshAirtableEndpoints: function () {
      this.refreshingAirtable = true;
      window.API.getProjectOperations(this.proj.id, { noCache: 1, nocache: 1 }).then((ops) => {
        this.refreshingAirtable = false;
        this.$buefy.toast.open({
          message: "Updated spec, refresh this tab please.   ",
          type: "is-success",
        });
      });
    },

    save: function (event) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.proj;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };
      window.API.editProject(d.id, d).then((proj) => {
        this.posting = false;
        //proj updated!
      });
    },
  },
  computed: {
    airtableEditUrl() {
      var p = this.proj;
      var autoAirtable = p.airtableBase ? "https://airtable.com/" + p.airtableBase : null;
      return p.airtableUrl || autoAirtable;
    },
  },
  data() {
    return {
      prompt: "",
      ideas: [],
      saving: false,
      generating: false,

      // GRID

      columnDefs: columnDefs,
      columnDefsIdeas,
      columnDefsExample: [
        { field: "athlete", minWidth: 200 },
        { field: "age" },
        { field: "country", minWidth: 150 },
        { field: "year" },
        { field: "date", minWidth: 150 },
        { field: "sport", minWidth: 150 },
        { field: "gold" },
        { field: "silver" },
        { field: "bronze" },
        { field: "total" },
      ],

      gridApi: null,
      columnApi: null,
      defaultColDef: {
        editable: true,
        flex: 1,
        minWidth: 100,
        resizable: true,
      },
      rowData: null,
      newData: null,
      ///
      rawOps: [],
      loading: true,

      posting: false,
      showSwag: false,
      swagMeta: true,
      swagData: "",
      specFormat: "midrun",

      refreshingAirtable: false,
    };
  },
};
</script>
<style scoped>
h3.title.is-4 {
  /* sexy */
  font-size: 33px;
  font-weight: 100;
  line-height: 2em;
  margin-top: 0.5em;
}
</style>
