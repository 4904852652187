// This is a simple token store that stores tokens in local storage.
// It is used to store tokens for the various servers that the user has connected to.

const DEFAULT_TOKENS = [
  { id: "none", name: "public", email: "no token", d: "Public access", icon: "earth" },
  //{ id: "test1", d: "TEST 1", icon: "key", token: 11111 },
  // { id: "test3", d: "TEST 333", icon: "user", token: 33333333333 },
];

/*
const DEFAULT_TOKENS_dev = [
  { id: "none", name: "public", email: "no token", d: "No token local!", icon: "earth" },
  { id: "local44", d: "TEST 4", icon: "key", token: 222222224444 },
  { id: "local55554", d: "LOCAL 555", icon: "user", token: 5555555888888 },
];*/
const defaultServers = {
  prod: {
    id: "prod",
    url: "https://prod.DEFAULT.com",
    icon: "server",
    selectedTokenId: "none",
    tokens: DEFAULT_TOKENS,
  },
  dev: {
    id: "dev",
    url: "https://dev.DEFAULT.com",
    icon: "laptop",
    selectedTokenId: "none",
    tokens: DEFAULT_TOKENS,
  },
};

export default class TokenStore {
  constructor(projectId, latestProdUrl, latestDevUrl) {
    this.projectId = projectId;
    this.localStorageKey = `tokenStore-${projectId}`;
    this.servers = {};
    this.selectedServerId = null; //persisted
    this.load();
    // set the latest prod/dev urls
    if (latestProdUrl) {
      this.servers.prod.url = latestProdUrl;
    }
    if (latestDevUrl) {
      this.servers.dev.url = latestDevUrl;
    }
    this.save();
  }
  addServer(id, url) {
    // Add a server to the store. If no id is provided, the hostname of the url is used.

    if (!id) {
      id = new URL(url).hostname;
    }
    this.servers[id] = {
      id,
      url,
      tokens: [],
    };
    this.save();
  }
  addToken(serverId, token) {
    // Add a token to a server. If no id is provided, the email or name of the token is used.

    const server = this.servers[serverId];
    if (!server) {
      return;
    }
    // If no id is provided, use the email or name of the token.
    const tokenId = token.id || token.userId || token.email || token.user || token.name || token.token;
    token.id = tokenId;
    if (!token.icon) token.icon = "key";
    token.added = new Date();
    if (!token.d) token.icon = "key";
    let existingToken = server.tokens.find((t) => t.id === tokenId);
    if (existingToken) {
      // Update the existing token.
      Object.assign(existingToken, token);
    } else {
      server.tokens.push(token);
    }
    // this.setSelectedToken(serverId, tokenId); //select it!
    // doenst triger the event, TODO: fix
    this.save();
  }
  addTokenToSelectedServer(token) {
    console.log("ADDING TO CURRENT SERVER: ", token);
    var selected = this.getSelectedServer();
    if (selected) {
      this.addToken(selected.id, token);
    } else {
      console.log("No server selected");
    }
  }

  setSelectedToken(serverId, tokenId) {
    // Set the selected token for a server. If no token id is provided, the first token is used.
    console.log("🔑 setSelectedToken", serverId, tokenId);
    const server = this.servers[serverId];
    if (!server) {
      return;
    }
    if (!tokenId) {
      // If no token id is provided, use the first token.
      tokenId = server.tokens[0].id;
      console.log("No token id provided, using first token on server " + serverId + " for token id " + tokenId + ".");
    }
    server.selectedTokenId = tokenId;
    // ensure that the token exists
    var actualToken = server.tokens.find((t) => t.id === tokenId);
    if (!actualToken) {
      server.selectedTokenId = null;
      console.log("Token not found on server " + serverId + " for token id " + tokenId + ".");
    }
    this.save();
  }
  // Get a server by id.
  getServer(serverId) {
    return this.servers[serverId];
  }

  getSelectedServer() {
    // Get the selected server.
    /*for (const id in this.servers) {
      if (this.servers[id] == this.selectedServerId) {
        return this.servers[id];
      }
    }*/
    //rewriten shorter:
    var s = this.servers[this.selectedServerId];
    if (s) {
      return s;
    }

    return this.servers["prod"]; //default
    //return null;
  }
  getSelectedServerTokenList() {
    // Get the selected server.
    const server = this.getSelectedServer();
    if (!server) {
      return DEFAULT_TOKENS;
    }
    return server.tokens || []; // array of tokens
  }
  getSelectedToken() {
    // Get the selected token, on current server.
    const server = this.getSelectedServer();
    //if (!server) return "none";
    //var toks = server.tokens || DEFAULT_TOKENS; // array of tokens
    return server.tokens.find((t) => t.id === server.selectedTokenId) || DEFAULT_TOKENS[0]; //first token (public access
  }
  setSelectedServer(serverId) {
    // Set the selected token for a server. If no token id is provided, the first token is used.
    console.log("🎰 setSelectedServer", serverId);

    this.selectedServerId = serverId;
    this.save();
  }
  // Get all servers.
  getServers() {
    return this.servers;
  }

  save() {
    // Save the servers to local storage.
    localStorage.setItem(
      this.localStorageKey,
      JSON.stringify({
        servers: this.servers,
        selectedServerId: this.selectedServerId,
      })
    );
  }

  load() {
    // Load the servers from local storage.
    const storedJson = localStorage.getItem(this.localStorageKey);
    const data = JSON.parse(storedJson);
    /* if (!storedServers) {
      return;
    }*/
    //validation
    if (!data) {
      this.servers = defaultServers;
      this.selectedServerId = "prod";
      return;
    }

    if (!data.servers) {
      this.servers = defaultServers;
    } else {
      this.servers = data.servers;
    }

    this.selectedServerId = data.selectedServerId || "prod";
    //JSON.parse(storedServers);
  }
}

// export default TokenStore;

//const tokenStore = new TokenStore();
